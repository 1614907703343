import React, { useContext, useEffect, useState } from 'react';
import {
  Stack, 
  Alert,
  Typography,
  Box
} from "@mui/material/"
import EedemButton from "../widgets/EedemButton"
import RefsysCombo from './RefsysCombo';
import SearchCombo from './SearchCombo';
import SourceCombo from './SourceCombo';
import DigitalTwinContext from './DigitalTwinContext';
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import { fireEvent, useEvent } from "../utils/events";
import {GetArea} from "@SaferPlaces2023/safer-map"
import { isExtentValid, MAX_PIXELS } from '../utils/utils';


/**
 * 
 * @param {boolean} disableDownload Enable/disable the download of the DTM
 * @param {string} errorText Text shown on the button in case of error
 * 
 * @returns 
 */
const DownloadDTMPanel = () => {

  const [project, setProject] = useContext(QgisContext)
  const Q = new QgisProject(project, setProject)
  const [params, setParams] = useContext(DigitalTwinContext)
  const [toowide, setToowide] = useState(false)


  const handleDatasetChange = (event) => {
    setParams({...params, dataset: event.target.value.dataset, dataset_resolution: event.target.value.resolution})
  }

  //! questa deve stare qui. Decide se c'è un errore nel download  
  useEffect(() => {
    if (params.selected_area) {
      let isValid = isExtentValid(params.selected_area, MAX_PIXELS, params.dataset_resolution)
      setToowide(!isValid)
    }
  }, [params.selected_area, params.dataset_resolution])


  useEffect(() => {
    fireEvent("bbox:valid", !toowide)
  }, [toowide])

  
  /** set the map zoom to the new location (bbox) */
  const handleChangeLocation = (newBbox) => {
    Q.setExtent(newBbox, "EPSG:4326") 
  }

  /** after drawing the bbox, set the bbox param and the current extent in meters, 
   * then, reset dataset and reference system since they could no longer be valid
  */
  useEvent("bbox:drawend", (event) => {
    let newBbox = event.detail.wgs84 // in 4326
    if (newBbox) {
      setParams({ ...params,  bbox: newBbox })
      project.map.zoomToExtent(newBbox, "EPSG:4326", 50)
    }
  })

  
  /** Update the selected area */
  useEffect(() => {
    if (params.bbox) {
      let area = GetArea(params.bbox, "EPSG:4326")
      // console.log("bbox changed. New area: ", area, "m2\ndataset: ", params.dataset, "\nknown resolution: ", params.dataset_resolution, "pixels/m")
      setParams({
        ...params, 
        //dataset: null,                                  //<------- reset del dataset quando si cambia l'area
        //t_srs: null,                                    //<------- reset del refsys quando si cambia l'area
        // dataset_resolution: DATASET_MIN_RESOLUTION,    //<------- reset della resolution (riportata al minimo) quando si cambia l'area
        selected_area: area
      })
    }
  // eslint-disable-next-line
  }, [params.bbox])



  const setJid = (jids) => {
    setParams({...params, dtm_progress: 0, dtm_jid: jids[0]})
  }


  const getMissingParams = () => {
    let mandatory_params = []
    if (!params.dataset) mandatory_params.push("dataset")
    if (!params.bbox.length) mandatory_params.push("area of study")
    if (!params.t_srs) mandatory_params.push("spatial reference system")
    return mandatory_params.length ? mandatory_params.join(", ") : ""
  }


  return (
    <Stack spacing={2}>
      
      <Typography>Search or draw on map your area of interest</Typography>
      <Box sx={{marginBottom:3}}>
        
        {/* togliere il set dall'handle change - usare il context all'interno del componente per settare i parametri
         in questo caso, lasciare solo la set location */}
        <SearchCombo onChange={handleChangeLocation} />
      </Box> 
      
      <Typography>Then select a source and spatial reference system</Typography>
      <SourceCombo 
        onChange = {handleDatasetChange}
      /> 
      
      
      <RefsysCombo /> 
      
      {
        toowide ? 
        <Alert severity="error">{`The area is too large! `
                                  + (params.dataset ? `The selected dataset ${params.dataset} has a resolution of ${params.dataset_resolution} pixels per meter. ` : "") //`The datasets available in this portion of map have a minimum resolution of ${params.dataset_resolution} pixel per meter.`)
                                  + `Please select a smaller area`
                                  + (params.dataset ? ` or a dataset with a smaller resolution. ` : `. `)}
        </Alert>
        :
        <></>
      }
      {
        params.dtm_error ? 
        <Alert severity="error">{params.dtm_error}</Alert>
        :
        <></>
      }

      <EedemButton
        disabled = { Boolean(getMissingParams())}
        dataset={params.dataset} 
        bbox = {params.bbox}
        tooWideArea = {toowide} 
        band="elevation"
        onClick={setJid} //TODO aggiungere validazione dell'estensione
        progress={params.dtm_progress}
        textWhenDisabled = {
          toowide ? "The selected area is too large. " 
          : ""
      }
      >
        Download
      </EedemButton>
      
    </Stack>
  )
}
export default DownloadDTMPanel;