import { Button } from "@mui/material";
import { useState } from "react";
import { useConfirmDialog } from "react-mui-confirm";

export const ButtonConfirm = ({ children, ...props }) => {
    
    const [disabled, setDisabled] = useState(props.disabled)
    const confirmDialog = useConfirmDialog();
        
    if (props.hidden) {
        return null
    }

    const action = async(e) => {
        if (props.onClick && !disabled){
            setDisabled(true)
            await props.onClick(e)
            setDisabled(false)
        }
    } 

    const handleClick = (e) => {

        e.preventDefault()
    
        if (props.confirm){
            confirmDialog({
                title:  props.confirm.title||"Are you sure?",
                description: props.confirm.description||"If you click confirm, the action will be executed",
                dialogProps: {
                    //disableBackdropClick: true,
                    disableEscapeKeyDown: false,
                },
                onConfirm: () => action(e),
            });
        } else {
            action(e)
        }
    }

    return <Button {...props} disabled={disabled} onClick={handleClick}>{children}</Button>;
}  

