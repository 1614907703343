import { useContext, useEffect, useState } from "react"

import { 
  FormControlLabel, 
  Checkbox, 
  DialogContentText, 
  Button,
  DialogContent,
  Grid,
  Stack
} from "@mui/material"

import { QgisContext } from "@SaferPlaces2023/safer-map";

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';



export const StorageTankStep = function({scenario, setScenario, onPause}) {

    const featureName = "storagetank"
    const featureLabel = "tank"
    const value = "v"
    const unit = <>m<sup>3</sup></>

    /** true se tutte le features sono selezionate
     * in questo modo si tiene aggiornato il tasto "select all" */
    const areAllSelected = scenario.features.every((feature) => (feature.get("selected") || false))

    const [project, ] = useContext(QgisContext)
    const [allSelected, setAllSelected] = useState(true)
    
    
    /** setta il valore "selected" a false se non è presente 
     * nelle features (succede solo all'inizio) */
    for (let feature of scenario.features) {
        if (feature.get("selected") === undefined)
            feature.set("selected", allSelected)
    }

    /** Seleziona/deseleziona una singola feature */
    const selectFeature = (id, checked) => {
        let newFeatures = scenario.features
        newFeatures[id].set("selected", checked)
        setScenario({...scenario, features: newFeatures})
        setAllSelected(areAllSelected)
    } 

    /** Seleziona/deseleziona tutte le features */
    const toggleAllCheckboxes = () => {
        let newFeatures = scenario.features
        newFeatures.map((feature) => {
            feature.set("selected", !allSelected)
            return true
        })
        setAllSelected(!allSelected)
    }


    /** questo ha l'effetto di aggiornare l'elenco di 
    * features quando dalla pausa si torna al wizard */
    useEffect(() => {
        project.map.getFeaturesOf(featureName)
        .then(features => {
            let tanks = features.sort((f1,f2) => {return (f1.getId() - f2.getId())})
            setScenario({...scenario, features: tanks})
        })
    }, [scenario.activeStep, scenario.pause]) //eslint-disable-line


    return <>
        { 
          <DialogContent>
              <DialogContentText component="div">
                  Select Storage Tanks to simulate with {scenario.features.length}
              </DialogContentText> 
                  {
                    <Grid container sx={{
                                        border: 1,
                                        borderRadius: 3,
                                        padding:2, 
                                        mb: 2,
                                        display: "flex",
                                        height: 200,
                                        overflow: "hidden",
                                        overflowY: "scroll",
                                        }}
                        >
                            
                        {
                            

                            scenario.features.length ? 

                                scenario.features.map((feature) => {
                                    return <Grid item lg={3} key={feature.getId()}> 
                                        <FormControlLabel
                                            control={<Checkbox 
                                                        checked={(feature.get("selected"))} 
                                                        onChange={(evt) => {selectFeature(feature.getId(), evt.target.checked)}} 
                                            />} 
                                            label={
                                                <> {featureLabel}#{feature.getId()} ({feature.get(value)}{unit}) </>
                                            }
                                        />
                                    </Grid>
                                })
                                : <DialogContentText component="div"><b>No {featureLabel} found</b></DialogContentText>
                        }
                    </Grid>      
                  }
                  <Stack spacing={2} direction="row">
                      
                      <Button 
                          variant="contained" 
                          color="primary" 
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={onPause}>
                          New 
                      </Button>

                      {
                        // mostra il tasto "select all" solo se sono presenti features
                        scenario.features.length ? 
                        <Button 
                          variant="outlined" 
                          color={allSelected ? "warning" : "primary"}
                          onClick={toggleAllCheckboxes}
                          startIcon={allSelected ? <PlaylistRemoveIcon /> : <PlaylistAddCheckIcon/>}
                          >
                          {allSelected ? "Remove all" : "Use all"}
                        </Button>
                        : <></>
                      }
                  </Stack>
                  
          </DialogContent>
      } 
  </>        
}
