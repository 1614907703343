import { Slider } from "@mui/material"


export const TmaxSlider = ({ value, onChange }) => {

    const marks = [
        {label:"1h", value:1},
        {label:"2h", value:2},
        {label:"3h", value:3},
        {label:"4h", value:4},
        {label:"6h", value:6},
        {label:"12h", value:12},
        {label:"24h", value:24},
        {label:"48h", value:48}
    ]

    const valueLabelFormat = (value) => `${value}h`

    return (

        <Slider 
            value={value}
            onChange={onChange}
            valueLabelDisplay="on"
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            step={1}
            marks={marks}
            min={1}
            max={48}
        />

    )

}//end ManningSlider