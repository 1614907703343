import React, { useContext, useState, useEffect } from "react";
import {
  List,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,

} from "@mui/material";
import Divider from "@mui/material/Divider";
import { QgisContext, QgisProject, Tools } from "@SaferPlaces2023/safer-map";
import LayersIcon from "@mui/icons-material/Layers";
import { useEvent } from "../utils/events";
import { forceext } from "../utils/filesystem";
import { ToolsContext } from "../widgets/ToolManager";



export default function BluespotDialog() {
  const [project, ] = useContext(QgisContext);
  const [open, setOpen] = useState(false);
  const [, setActiveTool] = useContext(ToolsContext)
  const [layers, setLayers]   = useState([]);
  const Q = new QgisProject(project)

  useEffect(() => {
    // compute infiltration layers
    // every time menu open")
    if (project.map && open){
      let maplayers = Q.getMapLayersByTag("scenario","rainfall")
      maplayers.push(...Q.getMapLayersByTag("scenario","river"))
      let layernames = maplayers.map(maplayer=>maplayer.layername.replace("WD_",""))
      setLayers( layernames )
    }
  }, [open]) // eslint-disable-line

  const handleSelect = (layerName) => {

    console.log("handleSelect", layerName)

    setActiveTool({
      name:       Tools.BLUESPOT_SELECT, 
      layerName:  layerName,
      eventName:  forceext("WD_"+layerName, "events")  //! this is weak: we assume that the event layer name is the same as the layer name with ".events" appended. We should instead provide a method to get the event file name, given the layer name (see alto ToolManager.js, find word "weak" in code)
    })
    handleClose()
  }

  function handleOpen(){
    setOpen(true)
  }

  function handleClose(){
    setOpen(false)
  }

  useEvent("bluespot-tool:open", handleOpen );

  return (<Dialog
      id="bluespots-dialog"
      aria-labelledby="bluespots-button"
      open={open}
    >
      <DialogTitle>Bluespots Layers</DialogTitle>
      <DialogContent>
        <Stack divider={<Divider orientation="horizontal" flexItem />}>
          <List>
              {
                    layers && layers.length ?
                    
                    layers.map(layername =>
                    
                      <ListItem disablePadding key={layername} style={{ padding: "10px" }} 
                      
                        onClick={() => handleSelect(layername)} >
                        <ListItemButton>
                          <ListItemIcon>
                            <LayersIcon/>
                          </ListItemIcon>
                          <ListItemText>{layername}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    )
                    : 
                    <>No layers</>
              }
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>)
}
