import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Grid } from "@mui/material";
import { useEvent} from "../../utils/events";
import { user_post } from "../../utils/http";

const VolumeChart = () => {

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "column",
      zoomType: "x",
      //height: "12%",
    },
    title: {
      text: "Volume",
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: "Volume (m<sup>3</sup>)",
        },
      },
      {
        min: 0,
        title: {
          text: "Height (m)",
        },
        opposite: true,
      },
    ],
    xAxis: {
      type: "category",
      title: {
        text: "Layer",
      },
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    series: [{ name: "Volume", data: [] }],
    legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "top",
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    tooltip: {
      pointFormat: "Volume: <b>{point.y:.1f} m<sup>3</sup></b>",
    },
  });

  const getVolumeFromEventData = (data) => {
    let series_volumes = [];
    for (let column of data) {
      series_volumes.push(column["total_volume"]);
    }

    return series_volumes;
  };

  const getLayersFromEventData = (data) => {
    let series_categories = [];
    for (let column of data) {
      series_categories.push(column["name"]);
    }
    return series_categories;
  };

  const getHeightFromEventData = (data) => {
    let series_wdheights = [];
    for (let column of data) {
      series_wdheights.push(column["max_wdepth"]);
    }
    return series_wdheights;
  };

  const handleVolumeTool = (event) => {
    const params = {
      features: event.detail.features,
      layers: event.detail.layers,
    };

    user_post("/api/volume-tool", params).then((response) => {
      if (response.data && response.data.status === "success") {
        const data = response.data.data;
        setChartOptions({
          xAxis: {
            categories: getLayersFromEventData(data),
          },
          series: [
            {
              type: "column",
              name: "Volume",
              data: getVolumeFromEventData(data),
              yAxis: 0,
              dataLabels: {
                enabled: true,
                format: "{point.y:.1f} m<sup>3</sup>",
              },
            },
            {
              type: "column",
              name: "Height",
              data: getHeightFromEventData(data),
              yAxis: 1,
              color: "#03254c",
              dataLabels: {
                enabled: true,
                format: "{point.y:.1f} m",
              },
            },
          ],
          tooltip: {  
            valueSuffix: () => this.series.name === "Volume" ? " m³" : " m",
            shared: true,
            crosshairs: true,
            useHTML: true,
            // headerFormat: '<span style="font-size: 10px">Water depth: {point.key} m</span><br/>Damage: <br/>',
            pointFormatter: function() {
                let suffix = this.series.name === "Volume" ? " m³" : " m"

                return `<span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b>${Math.round(this.y,2)} ${suffix}</b><br/>`
            }
        },
        });
      }
    });
  };

  useEvent("volume-tool:",handleVolumeTool)

  return (
    <Grid container item>
      <Grid item md={12}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Grid>
    </Grid>
  );
};

export default VolumeChart;
