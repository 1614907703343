import { useContext } from "react";
import { fireEvent, useEvent } from "./utils/events";
import { QgisContext, QgisProject} from "@SaferPlaces2023/safer-map";
import { api_features, user_delete, user_get, user_post } from "./utils/http";
import { MessageBoxShow } from "./widgets/StatusMessageBox";
import { len } from "./utils/strings";
import { juststem } from "./utils/filesystem";


export function EventManager() {
  
  const [project, ] = useContext(QgisContext); 
  
  const handleSaveProject = (event) => {
    //console.log("Saving project...", project)
    
    const project = event.detail.project;
    const clean = event.detail.clean;
    const Q = new QgisProject(project);
    return user_post("/api/project", {xml: Q.ExportToXml(), overwrite: true, clean:clean}).then(response=>{
      MessageBoxShow(response?.data?.data?.message,response?.data?.status)}
    )
  }

  const handleFeaturesUpdate = (event) => {
    
    const params = { 
      ...event.detail, 
      //project: project.projectname
    }

    api_features(params).then((response)=>{
      //project.map.refresh(params.layername)
      // Read featurecollection
      
      response = response.data
      response.layername = juststem(response.filename)
      
      if (response.layername && response.features && len(response.features) > 0) {
        let fid = response.features[0].id
        project.map.updateFeatureFID(response.layername, fid)
      }
    })//end then
  }

  const handleFeaturesDelete= (event) => {
    const params = {
      ...event.detail,
      //project: project.projectname
    }
    user_delete("/api/features", params).then((response) => {
      //project.map.refresh(params.layername)
      response = response.data 
      response.layername = juststem(response.data)  //response.data = filename
      project.map.removeFeatures(response.layername, response.fids)
      project.map.refresh(params.layername)
    });
  }

  const handleFDamageRead = () => {
    user_get("/api/fdamage/all?type=damage", {}).then((response) => {
       // response = [{"id":0,"name":"fun1","type":"damage","d1":1.0,"d2":2.0,"d3":3.0, ...},...]
       if (response && response.data)
       fireEvent("fdamage:all", response.data)
    });
  }

  const handleFDamageDelete = (event) => {
    user_delete("/api/fdamage/"+event.detail.id).then(() => {
      fireFdamageAll()
    })
  }

  /* refreshes the fdamage list */
  const fireFdamageAll = () => {
    user_get("/api/fdamage/all?type=damage", {}).then((response) => {
      fireEvent("fdamage:all", response.data)
    })
  }

  //save the fdamage points
  const handleFDamageSave = (event) => {
    user_post(`/api/fdamage/save`, event.detail).then(() => {
      fireFdamageAll()
    })
  }

  // recives the request as an event { fid: <...> }
  // the response is an event { feature: <...> }
  const handleDamagePreviewRead = (event) => {
    user_get(`/api/damage-preview/${event.detail.fid}`, {/*project: project.projectname*/})
      .then(response => {
          //alert("request for damage preview on feature " + event.detail.fid)
          fireEvent("damage-preview:all", response.data.feature)
      })
  } 

  useEvent("damage-preview:read", handleDamagePreviewRead)
  useEvent("project:save",  handleSaveProject);
  
  useEvent("feature:update", handleFeaturesUpdate);
  useEvent("feature:delete", handleFeaturesDelete);

  useEvent("fdamage:read", handleFDamageRead);
  useEvent("fdamage:save", handleFDamageSave);
  useEvent("fdamage:delete", handleFDamageDelete);

  


  return null;
}
