import React, { useContext, useEffect, useState } from "react";


import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Stack,
  Typography,
} from "@mui/material";

import {
  ExpandMore,
  OpacityOutlined,
  SettingsOutlined,
  LayersOutlined,
  CloudDownloadOutlined,
  WavesOutlined,
  PoolOutlined,
  InfoOutlined,
  ExtensionOutlined,
  HighlightAltOutlined
} from "@mui/icons-material";

import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import { RainFallWizard } from "../dialog/RainFallWizard";
import LayersClearOutlinedIcon from '@mui/icons-material/LayersClearOutlined';
import { ExtremeSeaLevelWizard } from "../dialog/ExtremeSeaLevelWizard";
import { IdentifyPlugin, BluespotPlugin } from "@SaferPlaces2023/safer-map";
import VolumeChart from "./charts/VolumeChart";
import DamageChart from "./charts/DamageChart";
import { RiverWizard } from "../dialog/RiverWizard";
import { useEvent } from "../utils/events";
import Jobs from "./JobsTable";

const COLOR = "#534CF7"

const ScenarioPanel = () => {

  const [project, ] = useContext(QgisContext);
  const [is_coastal, setIsCoastal] = useState(false)

  const [activeScenario, setActiveScenario] = useState(null)
  const scenarios = {river: "river", rain: "rain", sea: "sea"}

  /** Callback sulla chiusura di ogni wizard: setta activeScenario a null e sblocca i tasti laterali */
  const handleCloseWizard = () => {
    setActiveScenario(null)
  }

  useEffect(() => {
    const Q = new QgisProject(project)
    let coastal = Q.getProjectSetting("is_coastal")
    setIsCoastal( coastal)
  }, [project])

  return <>
    <Stack>
      {/*  ----------------- RAINFALL  -----------------*/}
      <Button
        color="primary"
        disabled = {activeScenario !== null}  // lo scenario è null quando nessuno dei 3 wizard è aperto. Se lo scenario è diverso da null, disabilita i pulsanti
        startIcon={<CloudDownloadOutlined />}
        style={{ margin: 5, justifyContent: "flex-start" }}
        onClick={() => {setActiveScenario(scenarios.rain)}}
        >
        Rainfall
      </Button>


      {/*  ----------------- RIVER  -----------------*/}
      <Button
        color="primary"
        disabled = {activeScenario !== null}
        startIcon={<WavesOutlined />}
        style={{ margin: 5, justifyContent: "flex-start" }}
        onClick={() => {setActiveScenario(scenarios.river)}}
        >
        River
      </Button>


      {/*  ----------------- SEA LEVEL  -----------------*/}
      <Button
        color="primary"
        disabled = {activeScenario !== null || !is_coastal}
        startIcon={<PoolOutlined />}
        style={{ margin: 5, justifyContent: "flex-start" }}
        onClick={() => {setActiveScenario(scenarios.sea)}}
        >
        Coastal
      </Button>
    </Stack>
    
    
    {
      (activeScenario === scenarios.rain)   ? <RainFallWizard handleClose={handleCloseWizard}/>
    : (activeScenario === scenarios.river)  ? <RiverWizard handleClose={handleCloseWizard}/> 
    : (activeScenario === scenarios.sea)    ? <ExtremeSeaLevelWizard handleClose={handleCloseWizard}/> 
    : <></>
    }
  </>
}


/**
 * Menu verticale con pannelli espandibili. 
 * Per aggiungere un nuovo pannello:
 * 1. aggiungere un nuovo elemento Accordion
 * 2. in AccordionSummary aggiungere onClick={() => handleExpandPanel("<nome_pannello>")}
 */
export default function SaferMenu({toggleLayerSwitcher, layersVisible, simple}) {

  const [expandedPanel, setExpandedPanel] = useState(null)
  const SimpleView = simple
  
  useEvent("simulation:start", () => setExpandedPanel("control"))
  
  useEvent("bluespot-tool:identify", (e) => {
    if (e.detail.visible)
      setExpandedPanel("bluespot")
  })

  useEvent("identify", (e) => {
    if (e.detail.visible) 
      setExpandedPanel("identify")
  })

  useEvent("volume-tool:", (e) => {
    if (e.detail.visible) 
      setExpandedPanel("volume")
  })

  useEvent("damage-tool:", (e) => {
    if (e.detail.visible) 
      setExpandedPanel("damage")
  })


  /** Expand a panel and close the others. If the panel is already expandend, close it.  */
  const handleExpandPanel = (panel) => {
    setExpandedPanel(expandedPanel === panel ? null : panel)
  }


  return (
    <>

      {/*  ----------------- SOURCE SCENARIOS  -----------------*/}
      <Accordion hidden={SimpleView} expanded={expandedPanel === "scenarios"}>
        
        <AccordionSummary expandIcon={<ExpandMore />} onClick={() => handleExpandPanel("scenarios")}>
          <Stack direction={"row"} spacing={2}>
            <OpacityOutlined style={{ color: COLOR }} />
            <Typography>Source Scenarios</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <ScenarioPanel />
        </AccordionDetails>

      </Accordion>

     
      {/*  ----------------- NEW CONTROL PANEL  -----------------*/}
      <Accordion hidden={SimpleView} expanded={expandedPanel === "control"}>
        <AccordionSummary onClick={() => handleExpandPanel("control")} expandIcon={<ExpandMore />} >
          
          <Stack direction={"row"} spacing={2}>
            <SettingsOutlined style={{ color: COLOR }} />
            <Typography>Control Panel</Typography>
          </Stack>
          
        </AccordionSummary>

        <AccordionDetails sx={{padding: 0}}>
          
          <Jobs />
        </AccordionDetails>
      </Accordion>


      {/*-------------------------- IDENTIFY --------------------------------*/}
      <Accordion
        onClick={() => {handleExpandPanel("identify")}}
        expanded={expandedPanel === "identify"} 
        style={{ margin: 0 }}
      >
        <AccordionSummary expandIcon={<ExpandMore />} style={{ margin: 0 }}>
          <Stack direction={"row"} spacing={2}>
            <InfoOutlined style={{ color: COLOR }} />
            <Typography>Identify</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <IdentifyPlugin />
        </AccordionDetails>
      </Accordion>


      {/*---------------------------- BLUESPOT IDENTIFY -----------------------------*/}
      <Accordion hidden={SimpleView} 
        onClick={() => handleExpandPanel("bluespot")}
        expanded={expandedPanel === "bluespot"}
      >
        <AccordionSummary expandIcon={<ExpandMore />} >
          <Stack direction={"row"} spacing={2}>
            <ExtensionOutlined style={{ color:  COLOR }} />
            <Typography>Bluespot Identify</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <BluespotPlugin />
        </AccordionDetails>
      </Accordion>

      
      {/*---------------------------- VOLUME CHART -----------------------------*/}
      <Accordion hidden={SimpleView} 
        onClick={() => handleExpandPanel("volume")}
        expanded={expandedPanel === "volume"} 
      >
        <AccordionSummary expandIcon={<ExpandMore />} >
          <Stack direction={"row"} spacing={2}>
            <HighlightAltOutlined style={{ color: COLOR }} />
            <Typography>Volume Chart</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <VolumeChart />
        </AccordionDetails>
      </Accordion>
      
      {/*---------------------------- DAMAGE CHART -----------------------------*/}
      <Accordion hidden={SimpleView} 
        onClick={() => handleExpandPanel("damage")}
        expanded={expandedPanel === "damage"} 
        >
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <Stack direction={"row"} spacing={2}>
            <HighlightAltOutlined style={{ color: COLOR }} />
            <Typography>Damage Chart</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <DamageChart />
        </AccordionDetails>
      </Accordion>

      {/*------------------------- LAYERS --------------------------------*/}
      <Accordion expanded={false} style={{ margin: 0 }} >
        <AccordionSummary onClick={toggleLayerSwitcher}>
          <Stack direction={"row"} spacing={2}>
              {
                layersVisible ? <LayersClearOutlinedIcon style={{ color: COLOR }} /> : <LayersOutlined style={{ color: COLOR }} />
              }
            <Typography>Layers</Typography>
          </Stack>
        </AccordionSummary>
      </Accordion>    
    </>
  )
}
