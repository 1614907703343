import "./App.css";
import React from "react";
import Paths from "./lib/utils/paths";
import 'react-reflex/styles.css'
import { Route, Routes } from "react-router-dom";
import LoginPage from "./lib/pages/LoginPage";
//import LoginEntraID from "./lib/pages/LoginEntraID";
import RegistrationPage from "./lib/pages/RegistrationPage";
import ForgotPasswordPage from "./lib/pages/ForgotPasswordPage";
import { ThemeProvider } from '@mui/material/styles';
import {SPLightTheme} from "./lib/themes"
import { BrowserRouter } from 'react-router-dom'
import { MyProjectsPage } from "./lib/pages/MyProjectsPage";
import ProjectPage from "./lib/pages/ProjectPage"
import NewProjectPage from "./lib/pages/NewProjectPage";
//EntraID
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import TestPage from "./lib/pages/TestPage";


const msalInstance = new PublicClientApplication(msalConfig);

function App() {

  return (
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={SPLightTheme}>       
          <BrowserRouter>
            <Routes> 
                <Route exact path={Paths.ROOT} element={<LoginPage/>} />
                <Route exact path={Paths.LOGIN} element={<LoginPage/>} />
                <Route exact path={Paths.FORGOT_PASSWORD} element={<ForgotPasswordPage/>} /> 
                <Route exact path={Paths.SIGNIN} element={<RegistrationPage />} /> 
                <Route exact path={Paths.NEW_PROJECT} element={<NewProjectPage />} />
                <Route exact path={Paths.MY_PROJECTS} element={<MyProjectsPage/>} />
                <Route exact path={Paths.PROJECT_PAGE} element={<ProjectPage/>} />
                <Route exact path={Paths.TEST_PAGE} element={<TestPage/>} />                             
            </Routes>  
          </BrowserRouter>
        </ThemeProvider> 
      </MsalProvider>
  );
}
export default App;
