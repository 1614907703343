import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getReferenceSystem } from "../utils/requests";
import DigitalTwinContext from "./DigitalTwinContext";
// import { useEvent } from "../utils/events";

/**
 * Controlled autocomplete text field. Options are dynamically retrieved basing on the box. 
 * @param {Array} bbox
 */
const RefsysCombo = ({onChange}) => {

    const [options, setOptions] = useState([])
    const [params, setParams] = useContext(DigitalTwinContext)
    // const [extent, ] = useState([])


    //! Qando si cambia area o bbox, devono resettarsi non solo le opzioni, ma anche il valore.
    const [value, setValue] = useState(options.length ? options[0] : null);
    const [inputValue, setInputValue] = useState('');
    const [fetching, setFetching] = useState(false)

    //? const [suggestion,] = useState("") 
    /* 
      ? ⬆︎ usato per avvalorare il campo automaticamente (senza interagire col componente), 
      ? sfruttando il placeholdeer. Se lasciato vuoto, viene usato il placeholder di default
      ? PRO: Comodo per velocizzare l'operazione senza dover cliccare sulla select. 
      ? CONTRO: Il campo già avvalorato sembra un'opzione non modificabile 
    */
    
    const updateOptions = (referenceBox) => {
        if (referenceBox && referenceBox.length) {
            setFetching(true)
            setOptions([])

            getReferenceSystem(referenceBox)
            .then((response) => response?.data)
            .then((response) => {
                if (response && response.status === "success") {
                    const optionsList = response.data.map((option) => (
                        {label: option.epsg, description: option.description}
                    ))
                    // if the new options does not include the already selected one, reset the field and THEN change the options
                    if (value && optionsList) {
                        let labels = optionsList.map((option) => option.label)
                        if (!labels.includes(value)) {
                          setValue(null)
                        //   console.error("dataset ", value, "non più disponibile in", optionsList)
                        }
                    }     
                    setOptions(optionsList)
                }
            })
            setFetching(false)
        }
    }

    const handleChange = (event, option) => {
        setParams({...params, t_srs: option.label})
        setValue(option.label)
    }

    /** When the refsys is reset, clear the box */
    useEffect(() => { 
        if (!params.t_srs) {
          setValue(null)
        }
    }, [params.t_srs])

    
    return <>
       
        <Autocomplete
            clearOnEscape
            disableClearable

            options={options}
            value = {value}
            onChange = {handleChange}
            
            
            inputValue = {inputValue}
            onInputChange = {(event, newInputValue) => {
                setInputValue(newInputValue);
            }}

            onOpen = {() => updateOptions(params.bbox)}
            noOptionsText = {fetching ? "Loading reference systems..." : "Select an area first"}

            renderOption={(props, option) => {
                return (
                <li {...props} key={option.label}>
                    <p> {option.label} <br/><small>({option.description})</small> </p>
                </li>
                );
            }}

            isOptionEqualToValue = {(option, selectedOptionLabel) => (option.label === selectedOptionLabel)}
            
            // onFocus = {() => setSuggestion("")}
            // onBlur = {() => setSuggestion(options.length ? options[0].label : "")}

            renderInput={(props) => (
                // <TextField {...props} label={suggestion || "Reference system"} />
                <TextField {...props} label={"Reference system"} />
            )}
            />
    </>
}
export default RefsysCombo