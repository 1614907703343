import React from "react";
import { useState, useEffect } from "react"
import {Box, Grid, Paper, TextField, Button, IconButton, Tooltip} from "@mui/material"
import { LoadingButton } from '@mui/lab';

import { useNavigate } from 'react-router-dom';
import { isEntraID, login } from '../utils/http';
import Paths from "../utils/paths";
import { loginBoxStyle } from "../themes"
import background1 from "../images/rect5.png"
import background2 from "../images/Microsoft_login_screen.svg"
import saferplaces_logo from "../images/Safer Places - Logo.png";
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility as VisibilityIcon, 
         VisibilityOff as VisibilityOffIcon} from '@mui/icons-material';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
//EntraID
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";



export default function LoginPage() {

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated(); // Hook to check if user is authenticated
    const navigate = useNavigate()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [error, setError] = useState({error: false, msg:""})            // text shown under the input filed which describes the error situation
    const [waitPlease, setWaitPlease] = useState(false)

    const isEntraUser = isEntraID(username)
    const background = isEntraUser ? background2: background1

    const handleUsernameChange = (event) => {

        let text = `${event.target.value}`.trim().toLowerCase()
        
        // remove all characters that are not valid for file names
        text = text.replace(/[^a-z0-9-@.]/g, "")

        // trim .. from the username
        text = text.replace("..", "")

        setUsername(text)
    }

    const handlePasswordChange = (event) => {

        setPassword(event.target.value)
    }
    
    const handleChangePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    function handleGoToForgotPassword() {
        sessionStorage.setItem("email", username)
        navigate(Paths.FORGOT_PASSWORD)
    }

    function handleGoToSignup() {
        navigate(Paths.SIGNIN)
    }
    
    const passwordVisibilityToggle = () => passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />

    const handleLogin = (e) => {
        setWaitPlease(true)
        
        if (isEntraUser) {
            //EntraID - login on useEffect
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
            return
        }

        login(username, password).then((response) => {
            if (response?.key) {
                navigate(Paths.MY_PROJECTS)
            } else {
                setError({error: true, msg:response.message})
                setWaitPlease(false)
            }
        }).catch((error) => {
            setError({error: true, msg:"Undefined error"})
            setWaitPlease(false)
        })
    }
    

    useEffect(() => {

        if (isAuthenticated && accounts.length > 0) {

            login(accounts[0]?.username, "dontcare").then((response) => {
                if (response?.key) {
                    navigate(Paths.MY_PROJECTS)
                } else {
                    setError({error: true, msg:response.message})
                    setWaitPlease(false)
                }
            }).catch((error) => {
                setError({error: true, msg:"Undefined error"})
                setWaitPlease(false)
            })
        }


    }, [isAuthenticated]); //eslint-disable-line
    // Trigger effect when accounts changes

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx = {{backgroundImage:`url(${background})` }}
        >
            
        <Paper elevation={10} sx={loginBoxStyle}>

            <Grid container direction="column" aling="center">

                <Grid item >
                    <img src={saferplaces_logo} alt="saferplaces logo" height="40" style={{ padding: 5 }} />
                </Grid>

                <Grid item>
                <h2>Login</h2>
                </Grid>
                
                
                <Grid item>
                <TextField 
                    label="Username" 
                    name="username"
                    variant="outlined" 
                    fullWidth 
                    value={username} 
                    onChange={handleUsernameChange}
                    error={error.error}
                    margin="dense"
                    
                    />
                </Grid>

                <Grid item>
                <TextField 
                    type= {passwordVisible ? "text" : "password"}
                    disabled={isEntraUser}
                    label="Password" 
                    name="password"
                    variant="outlined" 
                    fullWidth value={password} 
                    onChange={handlePasswordChange}
                    helperText={error.msg}
                    error={error.error}
                    autoFocus
                    margin="dense"
                    InputProps={{
                       
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={passwordVisible ? "Hide password" : "Show password"}>                                
                                    <IconButton 
                                    //disabled={isEntraUser}
                                    onClick={handleChangePasswordVisibility}>
                                        {passwordVisibilityToggle()}
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                            
                          ),
                      }}
                    />
                </Grid>
                <Grid item>
                    <Button variant="text" onClick={handleGoToForgotPassword}>Forgot my password</Button>
                </Grid>
                
                <Grid item>
                    <LoadingButton type="submit" 
                            fullWidth 
                            color="primary" 
                            onClick={handleLogin}
                            variant="contained"
                            loading = {waitPlease}
                            startIcon={isEntraUser?<MicrosoftIcon/>:null}
                            sx ={{margin: "20px auto"}}
                            //sx={buttonStyle}
                            >
                        {isEntraUser ? "EntraID" : "Confirm"}
                    </LoadingButton>
                </Grid>

                <Grid item>
                    <Button 
                        fullWidth 
                        disabled = {isEntraUser}
                        type="text" 
                        onClick={handleGoToSignup}> 
                    Create an account </Button>
                </Grid>
               
             
            </Grid>
                
        </Paper>
        </Box>        
    )
}


