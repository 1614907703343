import strftime from "strftime"
import { addjob } from "./addjob"
import { forceext, justpath } from "./filesystem"
import { __drive__ } from "./const"
import { QgisProject } from "@SaferPlaces2023/safer-map"


/**
 * preprocessing
 */
export const preprocessing = (project)=>{
    //patch dtm and buildings that are move into project_dir
    const Q = new QgisProject(project)
    const projectname = Q.getProjectName()
    const is_coastal  = Q.getProjectSetting("is_coastal") 
    const HMS = strftime("%H%M%S")
    let filedtm = Q.getDemPath(__drive__)
    const fileobm = Q.getBuildingsPath(__drive__)
    const bbox = filedtm // we need to get the bbox from the dem

    //patch in order pre-dem and pre-rain to work with the new path
    if (filedtm.includes("SAFER002_DEM")){
        filedtm = justpath(filedtm) + "/SAFER002_DEM.tif"
    }

    // SAFER002 PREPROCESSING
    const options0 = {
        name:        `SAFER002${HMS}`,
        type:        "safer002",
        project:     projectname,
        fileout:     filedtm,
        mode:        "ecs",//"ecs",
        image:       "safer002",  // we need an ecr image
        bbox:        bbox,    // context .bbox
        note:        "Safer002 dem generation",
    }

    const options1 = {
        name:        `PRE-DEM${HMS}`,
        type:        "dem_preprocess",
        project:     projectname,
        filedtm:     filedtm,
        fileobm:     fileobm,
        //model:       "safer_rain",
        mode:        "local",
        note:        "Preprocessing dtm"
    }

    // safer_rain_script --pre --model safer_rain --dem "${params.filedem}" --building "${params.fileshp}"
    // SAFER_RAIN PREPROCESSING
    const options2 = {
        name:        `PRE-RAIN${HMS}`,
        type:        "safer_rain_preprocess",
        project:     projectname,
        filedtm:     forceext(filedtm,"bld.tif"),
        fileobm:     fileobm, //not used
        model:       "safer_rain",
        mode:        "local",
        note:        "Preprocessing for safer_rain"
    }
  
    // COAST PREPROCESSING
    const options3 = {
        name:        `PRE-COAST${HMS}`,
        type:        "safer_coast_preprocess",
        project:     projectname,
        filedtm:     filedtm,
        fileobm:     fileobm, //not used 
        model:       "safer_coast",
        mode:        "local",
        note:        "Preprocessing for safer_coast"
    }
    
    if (filedtm.includes("SAFER002_DEM")){
        return addjob(options0)
            .then(()=>addjob(options1))
            .then(()=>addjob(options2))
            .then((job)=>(is_coastal)?addjob(options3):job)

    } else
  
    return addjob(options1).then(()=>{
        addjob(options2)
        if (is_coastal)addjob(options3)
    })
    
    
  }//end preprocessing