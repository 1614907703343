import React, { useState, useContext, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import { listify } from "../utils/strings";

export function SelectFile(props) {
  
  const [project, ] = useContext(QgisContext);
  const Q = new QgisProject(project);
  const [value, setValue] = useState("");
  const [maplayers, setMapLayers] = useState([]);
  
  
  const handleChange = (event) => {
    setValue(event.target.value);
  };


  useEffect(() => {
    const type = props.type ? listify(props.type) : ["raster", "vector"];
    const tags = props.tags ? listify(props.tags) : null;

    const filterType = (maplayer) => {
      return type.includes(maplayer["@type"]);
    };

    let maplayers = []
    if (tags){
      for(let tag of tags){
          maplayers = [...Q.getMapLayersByTag("type", tag)]
      }
    }else {
      maplayers = project.map.getMapLayers()
    }

    maplayers = [...new Set(maplayers)]  //Unique
    maplayers = maplayers.filter(filterType)
    // sort by layername
    maplayers.sort((a, b) => {
      if (a.layername < b.layername) {
        return -1;
      }
      if (a.layername > b.layername) {
        return 1;
      }
      return 0;
    }); 

    setMapLayers(maplayers);
    
  }, [project, props.prefix, props.type]) //eslint-disable-line

  return (
    <Select style={{ width: 250 }} value={value} defaultValue={"..."} label="layer" {...props}>
        {
          maplayers.map((maplayer) => {
          return (
            <MenuItem
              key={maplayer.id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingLeft: 15,
              }}
              value = {maplayer.datasource}
              onClick={handleChange}
            >
              <span>
                {project.map.getMapLayerIcon(maplayer)}
                {maplayer.layername}
              </span>
            </MenuItem>
          );
        })
      }
    </Select>
  );
}
