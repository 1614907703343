import React, { useContext, useEffect, useState } from "react";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router";
import {TextField} from "@mui/material"
import DtmStep from "./DtmStep"
import DigitalTwinContext from "./DigitalTwinContext";
import { QgisContext, QgisProject, Tools, addLayerToProject } from "@SaferPlaces2023/safer-map";
import BuildingsStep from "./BuildingsStep";
import InfiltrationStep from "./InfiltrationStep";
import LithologyStep from "./LithologyStep";
import SaveProjectButton from "./SaveProjectButton"
import { ConfirmDialogProvider } from "react-mui-confirm";
import { useEvent } from "../utils/events";
import { len } from "../utils/strings";
import { Tutorial, tutorials } from "../widgets/Tutorial";
import { justext, juststem } from "../utils/filesystem";
import { listjob } from "../utils/addjob";
import Paths  from "../utils/paths";
import { MyStepper } from "./MyStepper";
import { useInterval } from "../utils/useinterval";
import { preprocessing } from "../utils/preprocessing";
import { __drive__, getUserName } from "../utils/const";
import UploadOtherLayersPanel from "./UploadOtherLayersPanel";
import { MAX_AREA } from "../utils/const";

export const DATASET_MIN_RESOLUTION = 30 // pixels per meter

/** new ProjectStepper */
export default function DigitalTwinCreator() {

    let navigate = useNavigate();
    

    const [params, setParams] = useState({
        projectname: "",
        dataset: undefined,
        dataset_resolution: DATASET_MIN_RESOLUTION,
        location_name: undefined, 
        selected_area: undefined, // superficie in metri quadrati dell'area correntemente selezionata (extent della mappa o bbox disegnata)
        
        t_srs: "",     // target reference system
        bbox: [],     // bounding box
        
        dtm: null,    // dtm path
        buildings: null,
        
        ir: null,      //infiltration_rate
        ir_fname: null,

        sand: null,
        sand_fname: null,
        
        clay: null,
        clay_fname: null,
        
        dtm_progress: null,
        dtm_jid: null,
        dtm_error: null,
        
        buildings_progress: null,
        buildings_jid: null,
        buildings_error: null,

        ir_progress: null,
        ir_jid: null,
        ir_error: null,

        lithology_progress: null,
        lithology_jid: null,
        lithology_error: null,

        // project settings
        enable_dmg_preview: false,
        is_coastal: false,
    })


    const [project, setProject] = useContext(QgisContext); 
    const Q = new QgisProject(project, setProject)
    const [error, setError] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [delay, setDelay] = useState(3000)
    const layer_group = "Digital Twin"
    const [jobs, setJobs] = useState([])
    const [projectname, setProjectname] = useState("")

    useInterval(() => {
        listjob()
        .then(response => { 
            //response.data.forEach(item=>console.log(item))
            if (response && response.data){
                setJobs(response.data)  
                setDelay(delay+Math.random())
            }
        })
    }, delay)
   
    // 
    useEffect(()=>{
        if (delay===-1){
            setDelay(-1)
            navigate(Paths.MY_PROJECTS)
        }
    // eslint-disable-next-line 
    }, [delay])

    const getFileout = (command) => {
        let regexp = /.*--out\s+"(.*?)".*/
        //Eccezione per ir_rate
        //if (command.includes("--inf "))   
        //    regexp = /.*--inf\s+"(.*?)".*/

        let fileout = command.replace(regexp, "$1");
        fileout = fileout.replace(__drive__,"")
        
        if (!fileout || fileout.includes("null"))
            console.error("\n\n\n FILEOUT IS NON VALID\nfileout: ", fileout, "\ncommand: ", command, "\n\n\n")
        return fileout
    }

    // eslint-disable-next-line
    useEffect(() => {
        if (jobs) {
            const dtm_job = jobs.filter((item) => item.jid === params.dtm_jid)

            let dtm_progress = dtm_job[0] ? dtm_job[0].progress : null
            let dtm_error = dtm_job[0] ? (dtm_job[0].error) : null

            const buildings_job = jobs.filter((item) => item.jid === params.buildings_jid)
            let buildings_progress = buildings_job[0] ? buildings_job[0].progress : null
            let buildings_error = buildings_job[0] ? buildings_job[0].error : null

            const ir_job = jobs.filter((item) => item.jid === params.ir_jid)
            let ir_progress = ir_job[0] ? ir_job[0].progress : null
            let ir_error = ir_job[0] ? ir_job[0].error : null

            const sand_job = jobs.filter((item) => item.jid === params.sand_jid)
            const clay_job = jobs.filter((item) => item.jid === params.clay_jid)
            let lithology_progress = (sand_job[0] && clay_job[0]) ? (sand_job[0].progress + clay_job[0].progress) / 2 : null
            let lithology_error = (sand_job[0] && clay_job[0]) ? (sand_job[0].error + clay_job[0].error) / 2 : null
        

            setParams({
                ...params, 

                dtm_progress: dtm_progress, 
                dtm: (dtm_job.length && dtm_progress === 100) ? getFileout(dtm_job[0].command) : params.dtm,
                dtm_error: dtm_error,

                buildings_progress: buildings_progress, 
                buildings: (buildings_job.length && buildings_progress === 100) ? getFileout(buildings_job[0].command) : params.buildings,
                buildings_error: buildings_error,

                ir_progress: ir_progress, 
                ir: (ir_job.length && ir_progress === 100) ? getFileout(ir_job[0].command) : params.ir,
                ir_error: ir_error,

                lithology_progress: lithology_progress, 
                lithology_error: lithology_error,
                //sand: (sand_job.length && lithology_progress === 100) ? getFileout(sand_job[0].command) : params.sand,
                //clay: (clay_job.length && lithology_progress === 100) ? getFileout(clay_job[0].command) : params.clay,
                
            })

            if (!params.sand && lithology_progress === 100) {
                setParams({...params, lithology_progress:100, sand: getFileout(sand_job[0].command), clay: getFileout(clay_job[0].command)})
            }
        }
    // eslint-disable-next-line 
    }, [jobs])


    useEffect(() => {
        if (params.t_srs) { 
            Q.setProjection(params.t_srs)
        }
        if (params.location_name && params.bbox.length) {
            project.map.setBBOX(params.bbox, "EPSG:4326") 
        }
    // eslint-disable-next-line 
    }, [params.t_srs, params.location_name]) // ri-disegno il riquadro quando  aggiorno t_srs ->  aggiorno bbox (bari->milano) 

    
    useEffect(() => {
        if (params.dtm) {
            //remove all dtm layers before adding the new one
            Q.removeLayersByTag("dtm")
            addLayerToProject({
                project:project, 
                filename:params.dtm,
                groupname:layer_group, 
                visible:true, 
                zoomToLayer:true, 
                expanded:true,
                permanent:true,
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.dtm])
    
    
    useEffect(() => {
        if (params.buildings) {
            if (justext(params.buildings)==="shp")  {// to avoid addLayerToProject on .dbf
                Q.removeLayersByTag("buildings") 
                addLayerToProject({
                    project:project, 
                    filename:params.buildings, 
                    groupname:layer_group, 
                    visible:true,
                    expanded:true, 
                    //zoomToLayer:true, //dont work well
                    cmapname:"buildings",
                    overwrite:true
                })
            }
        }
    // eslint-disable-next-line 
    }, [params.buildings])
    

    useEffect(() => {
        if (params.ir) {
            Q.removeLayersByTag("infiltration_rate")
            addLayerToProject({
                project:project, 
                filename:params.ir, 
                groupname:layer_group, 
                visible:false,
                expanded:true,
                permanent:true,
                cmapname:"infiltration_rate",
                fieldName: params.ir_fname || "PERM",
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.ir]) 

    
    useEffect(() => {
        if (params.sand) {
            Q.removeLayersByTag("sand")
            
            addLayerToProject({
                project:project, 
                filename:params.sand, 
                groupname:layer_group,
                visible:false,
                expanded:true, 
                permanent:true,  //==> project:save
                cmapname:"sand",
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.sand]) 

    
    useEffect(() => {
        if (params.clay) {
            Q.removeLayersByTag("clay")
            addLayerToProject({
                project:project, 
                filename:params.clay, 
                groupname:layer_group, 
                visible:false,
                expanded:true,
                permanent:true,
                cmapname:"clay",
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.clay]) 


    useEffect(()=>{
        if (params.ir && params.ir_fname){
            Q.removeLayer(juststem(params.ir))
            addLayerToProject({
                project:project, 
                filename:params.ir, 
                groupname:layer_group,
                visible:false,
                expanded:true,
                permanent:true,
                cmapname:"infiltration_rate",
                fieldName:params.ir_fname,
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.ir_fname])


    useEffect(()=>{
        if (params.sand && params.sand_fname){
            Q.removeLayer(juststem(params.sand))
            addLayerToProject({
                project:project, 
                filename:params.sand, 
                groupname:layer_group,
                expanded:true,
                permanent:true,
                fieldName:params.sand_fname,
                cmapname:"sand",
            })
        }
    // eslint-disable-next-line 
    }, [params.sand_fname])


    useEffect(()=>{
        if (params.clay && params.clay_fname){
            Q.removeLayer(juststem(params.clay))
            addLayerToProject({
                project:project, 
                filename:params.clay, 
                groupname:layer_group,
                expanded:true,
                permanent:true,
                fieldName:params.clay_fname,
                cmapname:"clay"
            })
        }
    // eslint-disable-next-line 
    }, [params.clay_fname])


    useEffect(()=>{
        if (params.zip){
            addLayerToProject({
                project:project, 
                filename:params.zip, 
                groupname:"Additional layers",
                visible:true, 
                zoomToLayer:true, 
                expanded:true,
                permanent:true,
                //cmapname:"viridis"
            })
        }
    // eslint-disable-next-line 
    }, [params.zip])


    /** Ture if at least one step is still loading. A step is considered to be loading if it 
     * exists a job id (jid) for it and the progress of the job is between 1 and 100 */
    const loading = () => {
        return (( params.dtm_jid                         && (params.dtm_progress >= 0        && params.dtm_progress < 100) )          ||
                ( params.buildings_jid                   && (params.buildings_progress >= 0  && params.buildings_progress < 100) )    ||
                ( params.ir_jid                          && (params.ir_progress >= 0         && params.ir_progress < 100) )           ||
                ( (params.sand_jid && params.clay_jid)   && (params.lithology_progress >= 0  && params.lithology_progress < 100) )
        )
    }


    const checkNameIsValid = (name) => {
        
        let error = (name === "" || (len(name) > 0 && len(name.trim()) === 0));
        setError(error)
        return error
    }


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleFinish = function(){

        checkNameIsValid(projectname)
        if (!error){
            
            //const is_coastal = Q.getTag(Q.getDemMaplayer(), "is_coastal")
            //Q.setProjectSetting("is_coastal", is_coastal)   // for legacy projects is_coastal does not exist and is null
            setParams({...params, projectname:projectname})
            //patch
            project.qgis["@saveUser"] = getUserName()
            project.qgis["@saveUserFull"] = getUserName() 
            project.qgis["@projectname"] = projectname
            //end patch
            preprocessing(project)
        }
        //navigate out
        setDelay(-1)
        
    };


    const handleChangeName =(event)=>{
        //setParams({...params, projectname:event.target.value})
        checkNameIsValid(event.target.value);
        setProjectname(event.target.value)
    }


    useEvent("toggleDrawingTool", (event) => {
        try {
            let enable = event.detail
            project.map.activateTool(Tools.BBOX, enable, {maxarea: MAX_AREA});
        }catch(e){
            console.error("Error in toggleDrawingTool", e)
        }
    })


    const validate = () => {
        
        if (!params.dtm || !params.buildings) {
            return false
        }

        let dtmLayer = project.map.getLayerByName(juststem(params.dtm)) // this returns null if the layer is not in the map
        let buildingLayer = project.map.getLayerByName(juststem(params.buildings)) // this returns null if the layer is not in the map
        
        return Boolean(dtmLayer) && Boolean(buildingLayer)
    }

    return (
        <>
        <DigitalTwinContext.Provider value={[params, setParams]}>
        <ConfirmDialogProvider>
            <Stack spacing={0}>
                <Box sx={{paddingTop: 3}}>   
                    <Typography variant="h5">Create the digital twin</Typography>
                </Box>

                <MyStepper activeStep={activeStep}>

                    {/* DTM STEP */}
                    <Step 
                        label = {<> DTM <Tutorial name={tutorials.DTM} /> </>}
                        progress = {params.dtm_progress}
                    >
                        <Stack spacing={1}>
                        
                            <DtmStep />

                            <Box sx={{ mb: 2 }}>
                                {/* <Button onClick={handleNext} variant="outlined" disabled={!params.dtm}> Next </Button> */}
                                <Button onClick={handleNext} variant="outlined" disabled={false}> Next </Button> {/* //!DEBUG : allow to skip DTM step */}
                            </Box>
                        </Stack>
                    </Step>


                    {/* BUILDINGS STEP */}
                    <Step 
                        label={<> Buildings <Tutorial name={tutorials.BUILDINGS}/> </>}
                        progress={params.buildings_progress}
                    >
                        <Stack spacing={1}>
                            
                            <BuildingsStep />

                            <Box sx={{ mb: 2 }}>
                                <Button onClick={handleBack}>Back</Button>
                                <Button onClick={handleNext} variant="outlined"> Next </Button>
                            </Box>
                        </Stack>
                    </Step>


                    {/* INFILTRATION STEP */}
                    <Step 
                        label={<>Infiltration rate<Tutorial name={tutorials.INFILTRATION} /></>}
                        progress={params.ir_progress}
                    >
                        <Stack spacing={1}>
                                
                            <InfiltrationStep />

                            <Box sx={{ mb: 2 }}>
                                <Button onClick={handleBack}>Back</Button>
                                <Button onClick={handleNext} variant="outlined">Next</Button>
                            </Box>
                        </Stack>
                    </Step>


                    {/* LITHOLOGY STEP */}
                    <Step 
                        label={<> Lithology <Tutorial name={tutorials.LITHOLOGY} /></>}
                        progress = {params.lithology_progress} 
                    >
                        <Stack spacing={1}>
                            
                            <LithologyStep />

                            <Box sx={{ mb: 2 }}>
                                <Button onClick={handleBack}>Back</Button>
                                <Button onClick={handleNext} variant="outlined">Next</Button>
                            </Box>
                        </Stack>
                    </Step>

                    {/* OTHER STEP */}
                    <Step 
                        label={<> Other Layers <Tutorial name={tutorials.LITHOLOGY} /></>}
                        progress = {params.other_progress} 
                    >
                        <Stack spacing={1}>
                            
                            <UploadOtherLayersPanel />

                            <Box sx={{ mb: 2 }}>
                                <Button onClick={handleBack}>Back</Button>
                                <Button onClick={handleNext} variant="outlined">Next</Button>
                            </Box>
                        </Stack>
                    </Step>


                    {/* FINAL STEP */}
                    <Step
                        label = {<> Save project</>}
                    >
                        
                        <Stack spacing={2} marginTop={2}>
                            
                            <Typography>Choose a name for your digital twin</Typography>
                            
                            <TextField
                                label="Project name"
                                helperText={error ? "You need to name your project first!" : null}
                                
                                onBlur={(event)=>checkNameIsValid(event.target.value)}
                                error={error}
                                value={projectname}
                                onChange={handleChangeName}
                            />          


                            <Box sx={{ mb: 2 }}>
                                
                            <Button onClick={handleBack}>Back</Button>

                            <SaveProjectButton 
                                projectName={projectname}
                                callback={handleFinish} 
                                variant="outlined" 
                                disabled={loading() || !validate() || error}
                                textWhenDisabled={loading() ? "Please wait all the downloads to be done before saving the project" : "DTM and buildings are required"}
                            />

                                
                            </Box>
                        </Stack>
                    </Step>
                
                </MyStepper>
               
            </Stack>
            </ConfirmDialogProvider>
        </DigitalTwinContext.Provider>
        </>
    );
}
