import { Slider } from "@mui/material"



export const TimeShootSlider = (props) => {

    const marks = [
        {label:"1'",  value:60},
        {label:"5'",  value:300},
        {label:"10'", value:600},
        {label:"15'", value:900},
        {label:"30'", value:1800},
        {label:"1h", value:3600},
        {label:"2h", value:7200},
        {label:"3h", value:10800},
    ]

    const valueLabelFormat = (value) => `${value}s`

    return (
        
        <Slider 
            value={props.value}
            onChange={props.onChange}
            valueLabelDisplay="on"
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            step={null}
            marks={marks}
            min={props.min || 60}
            max={10800}
        />

    )

}//end ManningSlider