import React, { useContext, useState } from "react";
import {IconButton, Grid, Tooltip} from "@mui/material/"
import {useNavigate} from "react-router-dom"
import Paths from "../utils/paths";
import { ArrowBack } from '@mui/icons-material';
import { SearchBox } from "@SaferPlaces2023/sui-vite"
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import { len } from "../utils/strings";


export const NewProjectTools = () => {

    let navigate = useNavigate()
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState("") // eslint-disable-line
    const [project, setProject ] = useContext(QgisContext)
    const Q = new QgisProject(project, setProject)

    const extent = Q.getExtent("EPSG:4326")
    const lon = len(extent)===4 ? extent[0] : undefined 
    const lat = len(extent)===4 ? extent[1] : undefined 

    const handleBack = () => {
        navigate(Paths.MY_PROJECTS)
    }

    const handleSearchTextChange = (event) => {

        setSearch(event.target.value)
    }

    const handleSearchSelect = (option) => {
        setSelected(option);
       
        let extent = null

        if (option?.properties?.extent){
            extent = option.properties.extent
        }else if (option?.geometry?.coordinates){
            const coords = option.geometry.coordinates
            if (len(coords)===2){
                const delta = 0.01
                extent = [coords[0]-delta, coords[1]-delta, coords[0]+delta, coords[1]+delta]
            }
        }

        if (extent){
            Q.zoomToExtent(extent, "EPSG:4326")
        }
        
    }

    return (

        <Grid container justifyContent="flex-end" columnSpacing={3} >

            <Grid item>
                <Tooltip title="Back to My Projects">
                    <IconButton onClick={handleBack} variant="contained" color="primary"> 
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item style={{ width:"400px"}}>
                <SearchBox
                    sx = {{width: 300, height: 40 }}
                    style={{position:'relative', top:'-20px', left:'4px', verticalAlign:'middle',padding:0, margin:0}} 
                    
                    label="Search"
                    value={search}
                    onChange={handleSearchTextChange}
                    placeholder="Search"
                    onSelect={handleSearchSelect}

                    lon = {lon}
                    lat = {lat}
                    //tags={["house", "street", "locality", "district", "city", "county", "state", "country"]}
                    //tags={["locality", "district", "city", "county", "state", "country"]}
                />
            </Grid>
           
        </Grid>

    )
}