import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import * as sm from "@SaferPlaces2023/safer-map";
import {version} from '../../utils/version';
import { Spacer } from "../../widgets/mui/Spacer";


const Package = (props) => {
    return <ListItemButton>
                <ListItemIcon>
                    <ArchiveIcon />
                </ListItemIcon>
                <ListItemText primary={props.name} />
            </ListItemButton>
}


const SoftwareUpdatePanel = (props)=>{

    return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <Package name={`Saferplaces ${version}`}/>
            <Package name={`safer-map ${sm.version}`}/>
            <Spacer />
            <Divider/>
        </List>
}

export {Package, SoftwareUpdatePanel};