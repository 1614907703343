import { Slider } from "@mui/material"



export const NLSlider = ({ value, onChange }) => {

    const marks = [
        {label:"5", value:5},
        {label:"10", value:10},
        {label:"20", value:20},
        {label:"25", value:25},
        {label:"50", value:50},
        {label:"100", value:100},
    ]

    const valueLabelFormat = (value) => `${value}x${value}`

    return (
        
        <Slider 
            value={value}
            onChange={onChange}
            valueLabelDisplay="on"
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            step={5}
            marks={marks}
            min={5}
            max={100}
        />

    )

}//end ManningSlider