import React from "react";
import { Box, Grid, Paper } from "@mui/material"
import { loginBoxStyle } from "../themes"
import background from "../images/rect5.png"



export default function TestPage() {

   
    console.log("TestPage...")

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx = {{backgroundImage:`url(${background})` }}
        >
            
        <Paper elevation={10} sx={loginBoxStyle}>

            <Grid container direction="column" aling="center">

               Test Page
               
             
            </Grid>
                
        </Paper>
        </Box>        
    )
}


