import React, { useState, useContext, useEffect} from "react";
import {
  Autocomplete,
  TextField
} from "@mui/material";
// import { useEvent } from "../utils/events";
import { getSources } from "../utils/requests";
import { handleFetchErrors } from "../utils/utils";
import DigitalTwinContext from "./DigitalTwinContext";
// import CircularProgress from '@mui/material/CircularProgress';
// import { constants } from "buffer";

/**
 * Autocomplete text field for Datasets. 
 * Wrapper for MUI autocomplete text field. Options are dynamically retrieved basing on the box. 
 * @param {function} handleChange (optional) function called when the selected value changes
 * @param {Array} bbox
 */
const SourceCombo = (props) => {

  const [options, setOptions] = useState([]);
  // const [extent, ] = useState([])
  const [params, setParams] = useContext(DigitalTwinContext)
  //! IL componente dev'essere CONTROLLATO: quando si cambia area o bbox, devono resettarsi non solo le opzioni, ma anche il valore.
  const [value, setValue] = useState(options.length ? options[0] : null)
  const [inputValue, setInputValue] = useState('')

  const [fetching, setFetching] = useState(false)
  

  
  /** When the map is zoomed/resized or the you draw a bbox on map, update the available sources */
  const updateOptions = (referenceBox) => {
    if (referenceBox && referenceBox.length) {
      setFetching(true)
      setOptions([])
      // console.log("\n[dataset]\tfetching...")
      getSources(referenceBox)
      .then((response) => handleFetchErrors(response))// && response.json())
      .then((response) => response.data)
      .then((response) => {

        if (response.status==="success") {
            const optionList = response.data
                                  .map((option) => ({dataset:option.dataset, label: option.label, resolution: option.resolution}))
                                  .sort((a, b) => a.resolution - b.resolution) // sort options ascending by resolution                  
          
            //console.log("recived\n", optionList)                      
            // if the new options does not include the selected one, reset the field and THEN change the options
            if (value && optionList) {
              let labels = optionList.map((option) => option.label)
              if (!labels.includes(value)) {
                setValue(null)
                // console.error("dataset ", value, "non più disponibile in", optionList)
              } 
            } 
            setOptions(optionList)
            //console.log("[dataset]\toptions updated", optionList.length)
            // update the resolution of the dataset if the dataset is not set, so to always consider the minimum resolution first
        } else {
          //console.error("[dataset]\tfetch failed. \nResponse.status == 200:\n", response.status)
        }
        setFetching(false)
      })
      //.then(() => console.log("[dataset]\t-----------------"))
    } else {
      //console.log("[dataset]\tno bbox")
    }
  }

 /** When the dataset is reset, clear the box */
  useEffect(() => { 
    if (!params.dataset) {
      setValue(null)
    }
  }, [params.dataset])


  const handleChange = (event, option) => {
    // console.log("[SourceCombo] change", option)
    if (option) {
      // console.log("[SourceCombo] change dataset", option)
      setParams({...params, dataset: option.dataset, dataset_resolution: option.resolution})
      setValue(option.label)
      if (props.onChange) {
        props.onChange({target:{value: option}})
      }
    } 
  }





  return <>
       
        <Autocomplete
            clearOnEscape
            disableClearable
            options={options}
            value = {value}
            onChange = {handleChange}
            inputValue = {inputValue}
            
            onInputChange = {(event, newInputValue) => {
                setInputValue(newInputValue);
            }}

            onOpen = {() => updateOptions(params.bbox)}
            noOptionsText = {fetching ? "Loading datasets..." : "Select an area first"}
             

            renderOption={(props, option) => {
              return (
              <li {...props} key={option.label}>
                  <p> {option.label} <br/> <small>@{option.resolution}m </small></p>
              </li>
              );
            }}  

            isOptionEqualToValue = {(option, selectedOptionValue) => (option.label === selectedOptionValue)}
            
            // onFocus = {() => setSuggestion("")}
            // onBlur = {() => setSuggestion(options.length ? options[0].label : "")}

            renderInput={(props) => (
                // <TextField {...props} label={suggestion || "Reference system"} />
                <TextField {...props} label={"Dataset"} />
            )}
            />
    </>
};

export default SourceCombo;
