import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useInterval } from "../../utils/useinterval"
import { Stack } from "@mui/system";
import { getResourcesUsage } from "../../utils/requests";
import { hugemem } from "../../utils/currency";


export default function ResourcesLabel() {
      
    const [status, setStatus] = useState({"cpu":0, "ram":0})
    
    const mega = 1024*1024
    const cpu = status.cpu>0?`${status.cpu}%`:""
    const ram = status.ram.toFixed(0)>0?`${hugemem(status.ram*mega)}`:""

    useInterval(()=>{
        getResourcesUsage().then(response=>{
          if (response && response.status===200){
             setStatus({...response.data.data}) 
          }
        })
    }, 3000)

    return (
      <Stack>
          <Typography fontSize={12}>CPU: {cpu}</Typography>
          <Typography fontSize={12}> RAM: {ram}</Typography>
      </Stack>
    )
}