import {
  Input, 
  InputAdornment, 
  DialogContentText, 
  FormHelperText,
  DialogContent,
  Grid
} from "@mui/material"



export const ExtremeSeaLevelStep = function ({scenario, setScenario}) {

    const handleEslChange = (event) => {
      setScenario({...scenario, esl: event.target.value });
      return true;
    };

    const handleDurationChange = (event) => {
      setScenario({...scenario, duration: event.target.value });
      return true;
    }
  
    return (
      <>
      <DialogContent>



        <Grid container spacing={2}>
        
          <Grid item lg={6}>

            <DialogContentText component="div">
              This is the extreme sea water level
            </DialogContentText>
            <Input
              value = {scenario.esl}
              textalign={'right'}
              type="number"
              endAdornment={<InputAdornment position="end">m</InputAdornment>}
              inputProps={
                {style:{textAlign:"right"}, 
                step:0.05,
                min: 0.0,
                max: 10.0
              }}
              onChange={handleEslChange}
            />
            <FormHelperText>sea level</FormHelperText>
          </Grid>

          <Grid item lg={4}>

            <DialogContentText component="div">
              Total duration of the event
            </DialogContentText>

            <Input
                type="number"
                value = {scenario.duration}
                textalign={'right'}
                endAdornment={<InputAdornment position="end">h</InputAdornment>}
                aria-describedby="standard-duration-helper-text"
                inputProps={{'aria-label': 'duration', style:{textAlign:"right"}, min:0.0, step:0.5}}
                onChange={handleDurationChange}
            />
            <FormHelperText id="standard-duration-helper-text">duration</FormHelperText>
  
  
          </Grid>

        </Grid>
      </DialogContent>
      
      </>
    );
  };
  
  
  
  