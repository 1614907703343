
/**
 * hugevalue - format a number to a human readable string
 * @param {*} value 
 * @returns 
 */
export const hugevalue = (value, decimals=1)=>{

    if (value>1e9) return `${(value/1e9).toFixed(decimals)} B`
    else if (value>1e6) return `${(value/1e6).toFixed(decimals)} M`
    else if (value>1e3) return `${(value/1e3).toFixed(decimals)} k`
    else return `${value.toFixed(decimals)}`
}


export const hugemem = (value, decimals=1)=>{

    const kilo = 1024
    const mega = 1024*kilo
    const giga = 1024*mega

    if (value>giga) return `${(value/giga).toFixed(decimals)} GB`
    else if (value>mega) return `${(value/mega).toFixed(decimals)} MB`
    else if (value>kilo) return `${(value/kilo).toFixed(decimals)} kB`
    else return `${value.toFixed(decimals)}`
}