import "../../App.css";
import { useState } from "react"
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { LayerSwitcher, QgisContext, SaferMap, SectionChart } from "@SaferPlaces2023/safer-map";
import SaferMenu from "../widgets/SaferMenu";
import { ConfirmDialogProvider } from "react-mui-confirm"
import { EventManager } from "../EventManager"
import { useSearchParams } from "react-router-dom"
import { useEvent } from "../utils/events"

import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import 'react-reflex/styles.css'
import AppWrapper from "../widgets/AppWrapper"
import ChartBox from "../dialog/ChartBox"
import { LeftPanelWidth, RightPanelWidth } from "../themes"
import { ToolManager } from "../widgets/ToolManager";
import { getUserName } from "../utils/const";


const IsSimpleView = (projectname)=>{
  return projectname === "Piano spiaggia"
}


const ProjectPage = () => {
   
  const [params,] = useSearchParams(); //eslint-disable-line

  const username = getUserName()//eslint-disable-line
  const projectname = params.get("projectname")
  sessionStorage.setItem("projectname", projectname)

  const fileqgs = `/projects/${username}/${projectname}/project.qgs`
  //const fileqgs = `/api/resource/${projectname}/project.qgs`
  
  const SimpleView = IsSimpleView(projectname);

  const [showChart, setShowChart] = useState(false);
  
  const [project, setProject] = useState({
    projectname: projectname,
    username: username,
  });

  const [layerSwitcherVisible, setLayerSwitcherVisible] = useState(true)

  const toggleLayerSwitcher = ()=>{
    setLayerSwitcherVisible(!layerSwitcherVisible);
  };

  useEvent("section:drawstart", ()=>setShowChart(true))
  useEvent("section:close", ()=>setShowChart(false))

  return (
    <ConfirmDialogProvider>
        <QgisContext.Provider value={[project, setProject]}>

          <AppWrapper toolbarType="editProject" simple={SimpleView}>
          <ToolManager/>

          <EventManager />
        
          <Grid
            container
            style={{
              height: "97%",
              flexWrap:
                "nowrap" /** maintain this nowrap otherwise it will wrap to next column when there is not enough space */,
            }}
            direction="column"
          >
        
           
            <ReflexContainer orientation="vertical" windowResizeAware={true}>
              
              <ReflexElement size={LeftPanelWidth} minSize={LeftPanelWidth}>
                <Box sx={{paddingLeft: 1}}>
                  <SaferMenu toggleLayerSwitcher={toggleLayerSwitcher} layersVisible={layerSwitcherVisible} simple={SimpleView}/>
                </Box>
              </ReflexElement>
              
                
              <ReflexSplitter propagate={true}/>
              
              <ReflexElement className="middle-pane">
                <SaferMap fileqgs={fileqgs}/>
              </ReflexElement>
              
              <ReflexSplitter propagate={true}/>
              
              {
                layerSwitcherVisible ?              
                  <ReflexElement className="right-pane" size={RightPanelWidth} minSize={RightPanelWidth}>
                      <LayerSwitcher style={{ width: 100, borderWidth: 2 }} />
                  </ReflexElement>
                : null // usa null anziché <></> in questo caso, altrimenti viene visualizzato il pannello vuoto
              }
            </ReflexContainer>
            
          </Grid>
          {
            <div style={showChart ? {display:"block"} : {display:"none"}} >
              <ChartBox >
                <SectionChart />
              </ChartBox>
            </div>
          }

          
          </AppWrapper>
        </QgisContext.Provider>

    </ConfirmDialogProvider>
  );
}
export default ProjectPage;
