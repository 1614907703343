import React  from 'react'
import { 
    Box,
    Typography, 
    FormHelperText,
    FormControl,  
    FormLabel, 
    FormControlLabel, 
    RadioGroup, 
    Radio,
    FormGroup,
    Checkbox, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    DialogContent, 
    DialogContentText,
} from '@mui/material' 
import GridOnIcon from '@mui/icons-material/GridOn';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import WaterIcon from '@mui/icons-material/Water';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import SettingsIcon from '@mui/icons-material/Settings'; 
import { ManningSlider } from '../widgets/mui/ManningSlider';
import { DeltSlider } from '../widgets/mui/DeltSlider';
import { TimeShootSlider } from '../widgets/mui/TimeShootSlider';
import { NLSlider } from '../widgets/mui/NLSlider';
import { TmaxSlider } from '../widgets/mui/TmaxSlider';

/*************************************************************************************************************
 *     4)   ModelStep                                                                                        *
 *************************************************************************************************************/
 export const ModelStep = function({scenario, setScenario}) {

    const handleModelChange = (evt) => {
        setScenario({...scenario, model:evt.target.value } )
    }

    //eslint-disable-next-line
    const handleModeChange = (evt) => {
        setScenario({...scenario, mode:evt.target.value } )
    }

    const handleDamageChange = (evt) => {
        setScenario({...scenario, dmg:evt.target.checked } )
    }
    
    const handleTmaxChange = (evt) => {
        setScenario({...scenario, tmax:evt.target.value } )
    }

    const handleNLChange = (evt) => {
        setScenario({...scenario, nl:evt.target.value } )
    }

    const handleManningChange = (evt) => {
        setScenario({...scenario, man :evt.target.value } )
    }

    const handleDeltChange = (evt) => {
        setScenario({...scenario, delt:evt.target.value } )
    }

    const handleTiChange = (evt) => {
        setScenario({...scenario, ti:evt.target.value } )
    }


    
    return <>
            <DialogContent>
                <DialogContentText component="div">
                    Select a model for the simulation
                </DialogContentText>         
                
                <FormControl>
                    <FormLabel></FormLabel>
                        <RadioGroup 
                            row
                            value={scenario.model}
                            onChange={handleModelChange}
                        >

                            {/* SAFERRAIN MODEL */}
                            <FormControlLabel 
                                control={
                                <Radio value="safer_rain"/>
                            } label="SaferPlaces" />
                            
                            {/* UNTRIM MODEL */}
                            <FormControlLabel control={
                                // <Radio value="untrim" disabled/>
                                <Radio value="untrim"/>
                            } label="UnTrim"/>

                    </RadioGroup>
                </FormControl>    
            
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <SettingsIcon/>
                        <Typography>Settings</Typography>
                    </AccordionSummary>
                    
                    <AccordionDetails>
                        <div hidden={scenario.model !== "untrim"}>  
                            <AccessAlarmsIcon/>
                            <Typography>
                                Tmax - Max time of simulation
                            </Typography>
                            {/* <Input
                                onChange={handleTmaxChange}
                                type="number"
                                endAdornment={<InputAdornment position="end">h</InputAdornment>}
                                inputProps={{'aria-label': 'weight', inputMode: 'numeric', pattern: '[0-9]*'}}
                                sx = {{width:80}}
                                value={scenario.tmax}
                            /> */}
                            <TmaxSlider
                                value= {scenario.tmax}
                                onChange={handleTmaxChange}
                                sx = {{width:80}}
                            />
                            <FormHelperText id="standard-weight-helper-text">tmax (h)</FormHelperText>
                        </div>

                        {/* <div hidden={scenario.model !== "untrim"}>  
                            <Typography>
                                Manning Coefficient
                            </Typography>
                            <Input
                                onChange={handleManningChange}
                                type="number"
                                //endAdornment={<InputAdornment position="end">--</InputAdornment>}
                                inputProps={{'aria-label': 'weight', inputMode: 'numeric', pattern: '[0-9]*', min:0.01, max:0.1, step:0.01}}
                                sx = {{width:80}}
                                value={scenario.man}
                            />
                            <FormHelperText></FormHelperText>
                        </div> */}

                        <div hidden={scenario.model !== "untrim"}>  
                            <WaterIcon/>
                            <Typography>
                                Manning Coefficient <b>{scenario.man}</b> (smaller is faster)
                            </Typography>
                            <ManningSlider
                                value= {scenario.man}
                                onChange={handleManningChange}
                            />
                            <hr/>
                        </div>

                        <div hidden={scenario.model !== "untrim"}>  
                            <GridOnIcon/>
                            <Typography>
                                nl - The number of pixel for each element side (cell = nl x nl pixels) 
                            </Typography>
                            {/* <Input
                                onChange={handleNLChange}
                                type="number"
                                endAdornment={<InputAdornment position="end">px</InputAdornment>}
                                inputProps={{'aria-label': 'weight', inputMode: 'numeric', pattern: '[0-9]*', min:2, max:100}}
                                sx = {{width:80}}
                                value={scenario.nl}
                            /> */}
                            <NLSlider
                                value= {scenario.nl}
                                onChange={handleNLChange}
                                sx = {{width:80}}
                            />
                            <FormHelperText>nl</FormHelperText>
                        </div>

                        <div hidden={scenario.model !== "untrim"}>  
                            <HourglassBottomIcon/>
                            <Typography>
                                Delta T - Time simulation step
                            </Typography>
                            {/* <Input
                                onChange={handleDeltChange}
                                type="number"
                                endAdornment={<InputAdornment position="end">s</InputAdornment>}
                                inputProps={{'aria-label': 'weight', inputMode: 'numeric', pattern: '[0-9]*', min:5, max:1200, step:5}}
                                sx = {{width:80}}
                                value={scenario.delt}
                            /> */}
                            <DeltSlider
                                value= {scenario.delt}
                                onChange={handleDeltChange}
                                sx = {{width:80}}
                            />

                            <FormHelperText>The time step simulation. Lower make simulation more precise but slower. A good value is between [600,900]s</FormHelperText>
                        </div>

                        <div hidden={scenario.model !== "untrim"}>  
                            
                                <PhotoCameraIcon/>
                                <Typography>
                                    Ti - Time shoot interval <b>{scenario.ti}s</b>
                                </Typography>
                            
                            <TimeShootSlider
                                value= {scenario.ti}
                                onChange={handleTiChange}
                                sx = {{width:80}}
                                min = {scenario.delt}
                            />
                            <FormHelperText>A shoot of simulation is made every {scenario.ti}s for a total of {scenario.tmax*3600 / scenario.ti} shoots</FormHelperText>
                            <FormHelperText hidden={scenario.tmax*3600 / scenario.ti < 100 } sx={{color:"#138535"}}>Too much shoots for this simulation! Decrease the Tmax or increase the time shoot interval.</FormHelperText>
                        </div>
                        

                        {/* <Typography>Execution Environment</Typography>
                            
                        <RadioGroup
                            row
                            value={scenario.mode}
                            onChange={handleModeChange}
                        >
                            <FormControlLabel value="local" control={<Radio />} label="local" />
                            <div hidden={scenario.model==="untrim"}><FormControlLabel value="lambda" control={<Radio />} label="lambda" /></div>
                            <FormControlLabel value="ecs" control={<Radio />} label="ecs" />
                        </RadioGroup> */}

                    </AccordionDetails>
                </Accordion>   

                <Box sx={{ m: 10 }} /> 

                <DialogContentText component="div">
                    Select the damage model
                </DialogContentText>         
                
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={scenario.dmg} onChange={handleDamageChange} />} label="Apply damage" />
                </FormGroup>
        </DialogContent>
    </>
 }

