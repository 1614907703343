import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router";
import DigitalTwinContext from "./DigitalTwinContext";
import { QgisContext, QgisProject, Tools, addLayerToProject } from "@SaferPlaces2023/safer-map";
import { BboxTextField } from "@SaferPlaces2023/sui-vite";
import { useEvent } from "../utils/events";
import { isArray, len } from "../utils/strings";
import { justext, juststem } from "../utils/filesystem";
import { listjob } from "../utils/addjob";
import Paths  from "../utils/paths";
import { useInterval } from "../utils/useinterval";
import { preprocessing } from "../utils/preprocessing";
import { __drive__, getUserName } from "../utils/const";
import EedemButton from "../widgets/EedemButton"
import { ConfirmDialogProvider } from "react-mui-confirm";
import SaveProjectButton from "./SaveProjectButton";
import { LinearProgressWithLabel } from "../widgets/mui/LinearProgressWithLabel";
import { fromExtent } from "ol/geom/Polygon";
import { getArea } from "ol/sphere";
import { Union } from "@SaferPlaces2023/safer-map/dist/utils/geometry";
import { MAX_AREA } from "../utils/const";
import { mean } from  "../utils/math";
import { Transform } from "@SaferPlaces2023/safer-map";



const BASINS = "Bacini_DQ60_PdG_21_E32_v3"

/** new ProjectStepper */
export default function DigitalTwinCreatorRER() {

    const layer_group = "Digital Twin"
    const navigate = useNavigate();
    
    const [params, setParams] = useState({
        
        projectname: "",
            
        bbox: [],     // bounding box
        t_srs: "EPSG:3857", // target reference system
            
        dtm: null,    // dtm path  path to the dtm file
        buildings: null,
        ir: null,      //infiltration_rate
        sand: null,      
        clay: null,
        
        
        dtm_jid: null,
        dtm_progress: 0,
        dtm_error: null,
                
        buildings_jid: null,
        buildings_progress: null,
        buildings_error: null,

        ir_jid: null,
        ir_progress: null,
        ir_error: null,

        sand_jid: null,
        sand_progress: null,
        sand_error: null,

        clay_jid: null,
        clay_progress: null,
        clay_error: null,

    })


    const [project, setProject] = useContext(QgisContext); 
    const Q = new QgisProject(project, setProject)

    
    const [delay, setDelay] = useState(3000)
    const [selectionTool, setSelectionTool] = useState(Tools.SELECT)
    const [toowide, setToowide] = useState(false)

    const [projectname, setProjectname] = useState("")
    const [projectExtent, setProjectExtent] = useState([])
    const [error, setError] = useState(false)

    useInterval(() => {

        listjob()
        .then(response => { 
            if (response && response.data){
                //leggere la response.data e aggiornare i parametri
                //setParams( )
                setParams({...params, ...readParamsFromJobsTable(response.data)})
                //force the reload of the callback function
                // acquiring the updated input args
                setDelay(delay+Math.random()) 
            }
        })
    }, delay)

    useEffect(() => {

    },[delay])
   
    useEffect(()=>{
        if (delay===-1){
            setDelay(-1)
            navigate(Paths.MY_PROJECTS)
        }
    // eslint-disable-next-line 
    }, [delay])

    useEffect(() => {
        const layer = project.map.getLayerByName(BASINS)
        const tool = project.map.getActiveTool()

        if (layer && (!tool || (tool && tool.get("name") !== selectionTool))) {
            
            //force the visibility of the layer when the selectionTool is SELECT
            if (selectionTool === Tools.SELECT){
                Q.setVisible(layer.get("id"), true)
            }

            project.map.activateTool(selectionTool, true, {layers:[layer], maxarea: MAX_AREA});
        }

    }, [selectionTool, project?.map?.getLayerByName(BASINS)]) // eslint-disable-line

    useEffect(() => {

        if (params.bbox && isArray(params.bbox) && len(params.bbox) === 4){

            //calculate area in m2
            const geom = fromExtent(params.bbox)
            const area =  getArea(geom, {projection: "EPSG:4326"})           
            setToowide(area>MAX_AREA)
        }

    }, [params.bbox]) // eslint-disable-line


    const getFileout = (command) => {
        let regexp = /.*--out\s+"(.*?)".*/
        //Eccezione per ir_rate
        //if (command.includes("--inf "))   
        //    regexp = /.*--inf\s+"(.*?)".*/

        let fileout = command.replace(regexp, "$1");
        fileout = fileout.replace(__drive__,"")
        
        if (!fileout || fileout.includes("null"))
            console.error("\n\n\n FILEOUT IS NON VALID\nfileout: ", fileout, "\ncommand: ", command, "\n\n\n")
        return fileout
    }

    const readParamsFromJobsTable = (jobs) => {
        if (jobs){

            //dtm
            let dtm_job = jobs.filter((item) => item.jid === params.dtm_jid)
            dtm_job = dtm_job.length > 0 ? dtm_job[0] : null
            const dtm_progress = dtm_job?.progress || 0
            const dtm_error = dtm_job?.error

            //console.log("dtm_job: ", dtm_job, "dtm_progress: ", dtm_progress, "dtm_error: ", dtm_error)

            //buildings
            const buildings_job = jobs.find((item) => item.jid === params.buildings_jid)
            const buildings_progress = buildings_job?.progress || 0
            const buildings_error = buildings_job?.error

            //infiltation rate
            const ir_job = jobs.find((item) => item.jid === params.ir_jid)
            const ir_progress = ir_job?.progress || 0
            const ir_error = ir_job?.error

            //sand
            const sand_job = jobs.find((item) => item.jid === params.sand_jid)
            const sand_progress = sand_job?.progress || 0 
            const sand_error = sand_job?.error

            //clay
            const clay_job = jobs.find((item) => item.jid === params.clay_jid)
            const clay_progress = clay_job?.progress || 0
            const clay_error = clay_job?.error

            const res = {
                dtm_progress: dtm_progress, 
                dtm_error: dtm_error,

                buildings_progress: buildings_progress, 
                buildings_error: buildings_error,

                ir_progress: ir_progress, 
                ir_error: ir_error,

                sand_progress: sand_progress, 
                sand_error : sand_error,

                clay_progress: clay_progress,
                clay_error : clay_error 
            }

            if (dtm_progress === 100){
                res.dtm = getFileout(dtm_job?.command)
            }
            if (buildings_progress === 100){
                res.buildings = getFileout(buildings_job?.command)
            }
            if (ir_progress === 100){
                res.ir = getFileout(ir_job?.command)
            }
            if (sand_progress === 100){
                res.sand = getFileout(sand_job?.command)
            }
            if (clay_progress === 100){
                res.clay = getFileout(clay_job?.command)
            }

            return res
        }
        return {}
    }

    
    
    useEffect(() => {
        if (params.dtm) {
            //remove all dtm layers before adding the new one
            Q.removeLayersByTag("dtm")
            addLayerToProject({
                project:project, 
                filename:params.dtm,
                groupname:layer_group, 
                visible:true, 
                zoomToLayer:true, 
                expanded:true,
                permanent:true,
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.dtm])
    
    
    useEffect(() => {
        if (params.buildings) {

            if (justext(params.buildings)==="shp")  {// to avoid addLayerToProject on .dbf
                Q.removeLayersByTag("buildings") 
                addLayerToProject({
                    project:project, 
                    filename:params.buildings, 
                    groupname:layer_group, 
                    visible:true,
                    expanded:true, 
                    //zoomToLayer:true, //dont work well
                    cmapname:"buildings",
                    overwrite:true
                })
            }
        }
    // eslint-disable-next-line 
    }, [params.buildings])
    

    useEffect(() => {
        if (params.ir) {
            Q.removeLayersByTag("infiltration_rate")
            addLayerToProject({
                project:project, 
                filename:params.ir, 
                groupname:layer_group, 
                visible:false,
                expanded:true,
                permanent:true,
                cmapname:"infiltration_rate",
                fieldName: "PERM",
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.ir]) 

    
    useEffect(() => {
        
        if (params.sand) {
            
            Q.removeLayersByTag("sand")
            
            addLayerToProject({
                project:project, 
                filename:params.sand, 
                groupname:layer_group,
                visible:false,
                expanded:true, 
                permanent:true,  //==> project:save
                cmapname:"sand",
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.sand]) 

    
    useEffect(() => {
        if (params.clay) {
            Q.removeLayersByTag("clay")
            addLayerToProject({
                project:project, 
                filename:params.clay, 
                groupname:layer_group, 
                visible:false,
                expanded:true,
                permanent:true,
                cmapname:"clay",
                overwrite:true
            })
        }
    // eslint-disable-next-line 
    }, [params.clay]) 


    /** Ture if at least one step is still loading. A step is considered to be loading if it 
     * exists a job id (jid) for it and the progress of the job is between 1 and 100 */
    const loading = () => {
        return (( params.dtm_jid                         && (params.dtm_progress >= 0        && params.dtm_progress < 100) )          ||
                ( params.buildings_jid                   && (params.buildings_progress >= 0  && params.buildings_progress < 100) )    ||
                ( params.ir_jid                          && (params.ir_progress >= 0         && params.ir_progress < 100) )           ||
                ( params.sand_jid                        && (params.sand_progress >= 0       && params.sand_progress < 100) )         || 
                ( params.clay_jid                        && (params.clay_progress >= 0       && params.clay_progress < 100) )
        )
    }


    const checkNameIsValid = (name) => {

        let isValid = len(name) > 0 && len(name.trim()) > 0;

        //# regular expression to check alphanumeric and space characters
        //projectname = re.sub(r"[^a-zA-Z0-9-_\s\(\)]", "", projectname)

        //check the regular expression
        isValid = isValid && name.match(/[a-zA-Z0-9'-_\s()]/g) !== null

        setError(!isValid)
        return isValid
    }

    const handleChangeName =(event)=>{
        checkNameIsValid(event.target.value)
        setProjectname(event.target.value)
    }

    const handleFinish = function(){

        if (checkNameIsValid(projectname)){
            
            setParams({...params, projectname:projectname})
            //patch
            project.qgis["@saveUser"] = getUserName()
            project.qgis["@saveUserFull"] = getUserName() 
            project.qgis["@projectname"] = projectname
            //end patch
            preprocessing(project)
        }
        //navigate out
        setDelay(-1)  
    };


    
    const validate = () => {
        
        if (!params.dtm || !params.buildings || !params.ir || !params.sand || !params.clay){ 
            return false
        }

        let dtmLayer = project.map.getLayerByName(juststem(params.dtm)) // this returns null if the layer is not in the map
        let buildingLayer = project.map.getLayerByName(juststem(params.buildings)) // this returns null if the layer is not in the map
        let infiltrationLayer = project.map.getLayerByName(juststem(params.ir)) // this returns null if the layer is not in the map
        let sandLayer = project.map.getLayerByName(juststem(params.sand)) // this returns null if the layer is not in the map
        let clayLayer = project.map.getLayerByName(juststem(params.clay)) // this returns null if the layer is not in the map
        
        return Boolean(dtmLayer) && Boolean(buildingLayer) && Boolean(infiltrationLayer) && Boolean(sandLayer) && Boolean(clayLayer)
    }

    const setJids = (jids) => {
        setParams({...params, 
            dtm_progress: 0, dtm_jid: jids[0], 
            buildings_progress: 0, buildings_jid: jids[1],
            ir_progress: 0, ir_jid: jids[2],
            sand_progress: 0, sand_jid: jids[3],
            clay_progress: 0, clay_jid: jids[4],
        })
        setDelay(delay+Math.random())
    }

    const handleSelectionToolChange = (event) => {

        setSelectionTool(event.target.value)
    }

    /** after drawing the bbox, set the bbox param and the current extent in meters, 
     * then, reset dataset and reference system since they could no longer be valid
     */
    useEvent("bbox:drawend", (event) => {
    
        const newBbox = event?.detail?.wgs84 // in 4326

        if (newBbox) {
            setParams({...params, bbox: newBbox})
            setDelay(delay+Math.random())
            //to make calculate the location of the city name
            setProjectExtent(newBbox)
        }
    })

    useEvent("rer:select", (event) => {
        const features = event.detail.selection;
        
        
        if (len(features) > 0){
            const firstFeature = features[0]
            
            const maplayer = Q.getMapLayerByName(BASINS)
            const fids = features.map(f => f.getId())
            const newBbox = maplayer.datasource.replace("/vsicurl/","")+"|fid="+fids.join(",")
            setParams({...params, bbox: newBbox})
            setDelay(delay+Math.random())


            const extent = firstFeature.getGeometry().getExtent()
            // convert the extent to the target reference system EPSG:4326
            const s_srs = Q.getProjection()
            
            const extent4326 = Transform(extent, s_srs, "EPSG:4326")
            //to make calculate the location of the city name
            
            setProjectExtent(extent4326)

            const extents = features.map( f => f.getGeometry().getExtent())
            const polygons = Union( extents )

            if (len(polygons) > 0){
                //in case of disjoint polygons, the union is an array of polygons
                const firstOne = polygons[0]
                //the following one calulates a wrong area
                //const area = getArea(fromExtent(firstOne.getExtent()), {projection: s_srs})
                const area = getArea(firstOne, {projection: s_srs})
                setToowide(area>MAX_AREA)
            }
        }
    })

    return (
        <Box sx={{padding: 3}}>
        <DigitalTwinContext.Provider value={[params, setParams]}>
        <ConfirmDialogProvider>
            <Stack spacing={0}>
                <Box sx={{paddingTop: 3}}>   
                    <Typography variant="h5">Create the digital twin</Typography>
                </Box>

                <Box sx={{paddingTop: 3}}>
                    <ToggleButtonGroup  value={selectionTool} exclusive onChange={handleSelectionToolChange}>
                        <ToggleButton value={Tools.SELECT} variant="outlined">Select by basin</ToggleButton>
                        <ToggleButton value={Tools.BBOX}   variant="outlined">Select by area</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                
                <Box sx={{padding: 3}}>
                    <LinearProgressWithLabel  label = "DTM" value={params.dtm_progress} sx={{height:"20px", margin:"5px"}}/>  
                    <LinearProgressWithLabel  label = "Buildings" value={params.buildings_progress}  sx={{height:"20px", margin:"5px"}}/>  
                    <LinearProgressWithLabel  label = "Infiltration" value={params.ir_progress}  sx={{height:"20px", margin:"5px"}}/>  
                    <LinearProgressWithLabel  label = "Sand" value={params.sand_progress}  sx={{height:"20px", margin:"5px"}}/>  
                    <LinearProgressWithLabel  label = "Clay" value={params.clay_progress}  sx={{height:"20px", margin:"5px"}}/>  
                </Box>

                <Box  display="flex" justifyContent="flex-end">
                    <EedemButton
                        dataset= {[
                            "LIDAR/RER2", 
                            "OSM/BUILDINGS",
                            "LANDUSE/RER",//"ESA/WorldCover/v100",
                            "SAND/RER",
                            "CLAY/RER"
                            ]}
                        tooWideArea = {toowide} 
                        t_srs = {params.t_srs}
                        bbox = {params.bbox}
                        band = {["elevation", "buildings", "Map", "b10", "b10"]}
                        onClick={setJids} 
                        progress={ mean([params.dtm_progress, params.buildings_progress, params.ir_progress, params.sand_progress, params.clay_progress]) }
                        textWhenDisabled = {
                            toowide ? "The selected area is too large. " 
                            : ""
                        }
                    >
                        Download
                    </EedemButton>
                </Box>

                


                <Stack>

                    <Box sx={{padding:3}}>  
                        
                        <Typography>Choose a name for your digital twin</Typography>
                        
                        <BboxTextField
                            
                            label="Project name"
                            helperText={error ? "You need to name your project first!" : null}
                            onBlur={handleChangeName}
                            error={error}

                            bbox={projectExtent} //bbox => setCityName()  cityName
                            value={projectname}  //cityName
                            onChange={handleChangeName}
                        />  
                        

                            <SaveProjectButton 
                                projectName={projectname}
                                callback={handleFinish} 
                                variant="outlined" 
                                disabled={loading() || !validate() || error}
                                textWhenDisabled={loading() ? "Please wait all the downloads to be done before saving the project" : ""}
                            />


                      

                    </Box>
                </Stack>
               
            </Stack>
            </ConfirmDialogProvider>
        </DigitalTwinContext.Provider>
        </Box>
    );
}
