import { useEffect, useState } from "react";
import { 
    Alert,
    Box, 
    Button, 
    CircularProgress, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    FormControl, 
    Grid, 
    IconButton, 
    InputLabel, 
    MenuItem, 
    Select, 
    Slider, 
    Stack, 
    TextField, 
    Tooltip,
    Typography
} from "@mui/material";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { len } from "../utils/strings";
import { fireEvent, useEvent } from "../utils/events";
import { damage_function, linear_interpolation } from "../utils/damage-function";
import { DamageBarChart } from "../widgets/charts/DamageBarChart";
import { hugevalue } from "../utils/currency";
import { NumberField } from "../widgets/fields/NumberField";
import { user_get } from "../utils/http";
import { DamageFunctionChart } from "../widgets/charts/DamageFunctionChart";
import { isNumber } from "../utils/strings";
import { JSONDownload } from "../utils/utils";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const SCENARIOS =   ["P", "R", "C"]
const RTS =         [2, 5, 10, 50, 100]
const PROJECTIONS = ["P1", "P2", "H"]




// Max element of the matrix
const maxValue = (matrix) => {
    return Math.max(...matrix.flat().flat())
}

 // ...
function aggregate(charts, aggregateFunction) {
    if (!len(charts))  
        return []
    switch (aggregateFunction) {
        case "Maximum":
            return max(charts)
        // case "Minimum":
        //     return min(charts)
        // case "Average":
        //     return avg(charts)
        default:
            return max(charts)
    }
}

//  takes in input an array m x n x p and returns an array n x m with the max values of each column
// TODO make this also for MIN and AVG
function max(charts) {
    let maxValues = [];
    for (let i = 0; i < charts[0].length; i++) {
        maxValues.push([]);
        for (let j = 0; j < charts[0][0].length; j++) {
            let max = charts[0][i][j];
            for (let k = 0; k < charts.length; k++) {
                if (charts[k][i][j] > max) {
                    max = charts[k][i][j];
                }
            }
            maxValues[i].push(max);
        }
    }
    return maxValues;
}



export const DamagePreviewDialog = () => {

    // const confirm = useConfirmDialog()
    // -------------------------------------------------------------------------
    //                               RIGHT PANEL 
    // -------------------------------------------------------------------------
    const [feature, setFeature] = useState(undefined)
    const [damagePoints, setDamagePoints] = useState([])
    const oldSeries = [...damagePoints]
    const [damageOptions, setDamageOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState("")
    const [plotPoints, setPlotPoints] = useState(selectedOption ? [...selectedOption.points] : damagePoints) // aggiorna questo stato per modificare i grafici 
    const [valueM2, setValueM2] = useState(0)                                           // questo è uno stato perché non vado a modificare il valore del feature (è in sola lettura)
    const [chartsWD, setChartsWD] = useState([])                                        // questo è uno stato perché highcharts non riesce a fare il refresh del grafico -> serve un re-render. Quindi ri-settiamo lo stato anziché calcolare semplicemente i nuovi valori
    const [presetName, setPresetName] = useState("custom_damage_function")
    const [presetSaved, setPresetSaved] = useState(false)
    const [open, setOpen] = useState(false)

    let featureOptions = {minValue: 100, maxValue: 5000, step: 100}
    let returnTimeValues = [2,5,10,50,100]
    const aggregateFunction = "Maximum"                                                 // funzione di aggregazione selezionata - in futuro potrà essere cambiata tramite una select quindi teniamo lo stato per ora       // eslint-disable-line no-unused-vars 
    const type = "damage"
    
    const SCENARIOS_LABELS =    ["Pluvial", "Fluvial", "Costal"]
    const RETURN_TIME_LABELS =  returnTimeValues.map(rt => `RT${rt}`)
    const PROJECTIONS_LABELS =  ["RCP4.5", "RCP8.5", "Historical"]
    const [area, setArea] = useState(undefined)

    const chartsDMG = computeDamageFunction(chartsWD, area, valueM2, plotPoints)    

    let buildingMaxValue = valueM2 * area                                               // valore massimo della feature selezionata
    const chartMaxWD = aggregate(chartsWD, aggregateFunction)                           // calcola il grafico max - genericamente può essere anche min o avg
    const chartMaxDMG = chartsDMG ? aggregate(chartsDMG, aggregateFunction) : []
    const maxEads = computeEAD(chartMaxWD, chartMaxDMG)

    const [interpolationYear, setInterpolationYear] = useState(2030) //eslint-disable-line no-unused-vars


    /**
     * Export the EADs to a JSON file
     */
    const handleExport = () => {
        let eadJSON = {}

        // compute eads
        let EADs = SCENARIOS_LABELS.map((_, j) => computeEAD(chartsWD[j], chartsDMG[j]))
        EADs.forEach((ead, i) => {
            let row = {}
            ead.forEach((value, j) => {row[PROJECTIONS_LABELS[j]] = value})
            eadJSON[SCENARIOS_LABELS[i]] = row
        })

        // adding max column
        maxEads.forEach((value, i) => {
            eadJSON["Max"] = {...eadJSON["Max"], [PROJECTIONS_LABELS[i]]: value}
        })

        // adding interpolate column
        let interpolateRow = {}
        maxEads.forEach((ead, i) => {
            if (PROJECTIONS_LABELS[i] !== "Historical")
                interpolateRow[PROJECTIONS_LABELS[i]] = linear_interpolation(ead, maxEads[PROJECTIONS_LABELS.indexOf("Historical")], 2050, 2020, 2030)
            else 
                interpolateRow[PROJECTIONS_LABELS[i]] = undefined
        })
        eadJSON["Interpolate"] = interpolateRow
       
        JSONDownload(eadJSON, "EADs_file")
    }

    
     
   

    useEffect(() => {
        if (area === undefined || area === 0) {
            let featureArea = feature ? feature.getGeometry().getArea() : 0
            featureArea = Math.round(featureArea, 2)
            setArea(featureArea)
        } else {
            //console.log("area already set: ", area)
        }
        
    }, [feature, area])


    useEvent("fdamage:all", (event) => {
        //console.warn("fdamage:all", event.detail)
        // transforma fdamage_table in un formato più semplice per rimuovere dipendenze tra dati memorizzati e dati visualizzati
        let fdamage_json = event.detail
        let fdamages = []
        fdamage_json.forEach(fdamage => {
          fdamages.push({
            id: fdamage.id,
            name: fdamage.name, 
            type: fdamage.type,
            points: [...Array(20).keys()].map(i => i === 0 ? 0 : fdamage["d" + i] * 100)
          })
        })
        setDamageOptions(fdamages)
      })
    

   

    /**
     * Extract damage points (d1-d19) from feature
     * @param {object} feature
     */
    const getPoints = (feature) => {
        if (!feature) {
            return []
        }
        let points = [0]
        for (let i = 1; i <= 19; i++) {
            let point = feature.get("d" + i) * 100 
            points.push(point || 0)
        }
        return points
    }


    function fetchWaterDepths(fid) {
        return user_get(`/api/damage-preview/${fid}`, {/*project: project.projectname*/})
    } 

    /**
     * reshape the list of values to a matrix of shape [SCENARIOS, PROJECTIONS, RETURN_TIMES]
    */
    function reshapeToMatrix(list) {
        const newShape = [SCENARIOS.length, PROJECTIONS.length, RETURN_TIME_LABELS.length]
        if (!list) {
            console.log(`Cannot reshape matrix: list is undefined`);
            return [];
        }
        if (list.length !== newShape[0] * newShape[1] * newShape[2]) {
            console.log(`Cannot reshape matrix: number of elements (${list.length})does not match the new shape (${newShape})`);
            return [];
        }
        const reshapedMatrix = [];
        for (let i = 0; i < newShape[0]; i++) {
          const row = [];
          for (let j = 0; j < newShape[1]; j++) {
            const sliceIndex = (i * newShape[1] + j) * newShape[2];
            row.push(list.slice(sliceIndex, sliceIndex + newShape[2]));
          }
          reshapedMatrix.push(row);
        }
        return reshapedMatrix;
    }

  
    /** Re-name the current series as a preset (ready to be saved) */
    const handleChangePresetTextField = (event) => {
        let newName = event.target.value
        if (newName.length <= 16) {
            setPresetName(newName)
            setPresetSaved(false)
            setSelectedOption("")
        }
    }


    /** Select a new preset option from the select component */
    const handleSelectPreset = (event) => {
        let newPreset = event.target.value
        setSelectedOption(newPreset)
        setPlotPoints(newPreset.points)
        // applyDamageFunction(newPreset.points)
    }


    /** The series has been modified (by drag-grop or by selecting an available preset) */
    const handleEditSeries = (newPoint) => {
        let newPoints = [...plotPoints]
        newPoints[newPoint.index] = newPoint.y
        for (let i = newPoint.index + 1; i < newPoints.length; i++) {              // for each point y after the new one, set that point to the maximum between it and the new point
            // newPoints[i] = Math.max(newPoints[i], newPoints[i-1])
            if (newPoints[i] < newPoint.y) {
                newPoints[i] = newPoint.y
            }
        }
        for (let i = newPoint.index - 1; i >= 0; i--) {                            // for each point y before the new one, set that point to the minimum between it and the new point
            // newPoints[i] = Math.min(newPoints[i], newPoints[i+1])
            if (newPoints[i] > newPoint.y) {
                newPoints[i] = newPoint.y
            }
        }
        setSelectedOption("")
        setPresetSaved(false)
        setPlotPoints(newPoints)
    } 


    /** Save the current series as preset */
    const handleSaveFDamage = () => {
        setPresetSaved(true)
        let new_fun = {"name": presetName,  "type": type }
        plotPoints.slice(1).forEach((point, index) => {
            new_fun["d"+(index+1)] = point / 100    
        })
        fireEvent("fdamage:save", new_fun)
    }

    
    // const handleDeletePreset = (fdamageId) => {
    //     fireEvent("fdamage:delete", {id: fdamageId})
    //     setSelectedOption("")
    // }

    
    
    /** apply the function to all the charts */
    function computeDamageFunction(charts, area, valueM2, series) {
        if (!series) {
            console.error("undefined series")
            return []
        }
        // console.log("computing damage function with area: " + area + " valueM2: " + valueM2 + " damagePoints: " + damagePoints)
        return charts.map(chart => chart.map(row => row.map(wd => damage_function(wd, area, valueM2, series))))
    }

    const handleChangeSliderValue = (event, newValueM2) => {
        setValueM2(newValueM2)
    }

    const handleChangeValueM2 = (event) => {
        setValueM2(event.target.value)
    }

    


    /**
     * Compute the Expected Annual Damage (EAD) for a given chart of water depth and damage. 
        A chart is a 3x5 matrix of water depth and damage for 5 different return periods (2, 5, 10, 50, 100 years) and 3 different projections (historical, rcp 4.5, rcp 8.5)
     * @param {*} chart_wd Matrix 3x5 of water depth
     * @param {*} chart_dmg Matrix 3x5 of damage
     */
    function computeEAD (chart_wd, chart_dmg) {

        if (!chart_wd || !chart_dmg || chart_wd.length !== chart_dmg.length) {
            // console.error("[Compute EAD]\nCharts must be non null, non empty and have the same length. Got\nwater depth: ", chart_wd, "\ndamage: ", chart_dmg)
            return []
        }
        
        let EADs = []
        for (let j = 0; j < chart_wd.length; j++) {
            let T = chart_wd[j],
                D = chart_dmg[j]
            let EAD = 0
            
            let indices = T.map((val, i) => (isNumber(val) && val !== 0) ? i : -1).filter(val => val !== -1)   // get the indices of the non zero values
            
            if (indices.length > 1) {                                                       // if there are at least two non zero values (otherwise the formula is inapplicable)
                for (let index = 0; index < indices.length - 1; index ++) { 
                    let i = indices[index]
                    let i_next = indices[index + 1]
                    EAD += (((1/T[i]) - (1/T[i_next])) * (D[i] + D[i_next]) / 2)
                }
                EADs.push(EAD)
            } else {
                EADs.push(undefined)
            }
        }
        return EADs
    }

    
    

    /**
     * Returns a list of waterdepths
     * @param {} feature 
     * @returns 
     */
    function getWaterDepths(values) {
        if (!values)
            return []

        let chartsWD = []

        SCENARIOS.forEach(scenario => {
            PROJECTIONS.forEach(projection => {
                RTS.forEach(rt => {
                    let key = `WD_${scenario}${rt}${projection}`
                    chartsWD.push(values[key] !== undefined ? values[key] : false)
                                        
                })
            })
        })        
        return chartsWD
    }


    // all values are undefined
    const isChartEmpty = (chart) => {
        if (!chart) return true
        return chart.flat().flat().every(val => val === false)
    }


    /** return true if there are at least two charts with data */
    const atLeastTwoCharts = (chart) => {
        return (chartsWD.filter(chart => !isChartEmpty(chart)).length > 1)
    }

    const handleAreaFieldChange = (event) => {
        // console.log("area field change: ", event.target.value, !isNaN(event.target.value))
        if (!isNaN(event.target.value)) {
            setArea(event.target.value)
        }
    }
    

    /** Opens the dialog and fetch data from selected features. If the feature has no data, the dialog is not opened */
    const handleOpen = (event) => {
        let feature = event.detail.features[0]
        if (feature) {
            // setOpen(true)
            setFeature(feature)
            setValueM2(feature.get("value_m2"))
            setDamagePoints(getPoints(feature))
            setPlotPoints(getPoints(feature))
            
            fetchWaterDepths(feature.getId())
                .then(response => response.data.feature.properties)
                .then(properties => {
                    //console.log("feature properties: ", properties, "\n\n")
                    let water_depths = getWaterDepths(properties)
                    setChartsWD(reshapeToMatrix(water_depths))                                  // reshape the water depths to a matrix
                })
                .then(() => setOpen(true))
        }
    }

    
    const handleClose = () => {
        setArea(undefined)
        setOpen(false)
    }


    /** When a new feature is selected */
    useEffect(() => {
        if (len(feature)) {
            let damagePoints = getPoints(feature)
            setDamagePoints(damagePoints)      
        }
    }, [feature]) //// eslint-disable-line
    

    /** When a new preset is selected */
    useEffect(() => {
        if (selectedOption) {
            setPresetName((selectedOption ? selectedOption.name : "damageFunction"))
        }
    }, [selectedOption])


    /** When the dialog is closed, reset the fields */
    useEffect(() => {
        if (!open) {
            setSelectedOption("")
            setPresetName("")
            setSelectedOption("")
            setPlotPoints(oldSeries)
            setChartsWD([]) 
        } 
    }, [open]) //eslint-disable-line

    useEvent("damage-preview:open", handleOpen)
    


    
    // LOADING 
    if (!chartsWD || !chartsDMG || !feature || !damageOptions || !damagePoints || !damagePoints.length || !chartsWD.length) {
        // if (!damagePoints.length)   console.log("\n***Series is empty")
        // if (!feature)               console.log("\n***Feature is null")
        // if (!damageOptions)         console.log("\n***Options is null")
        // if (!chartsWD)              console.log("\n***ChartsWD is null")
        // if (!chartsDMG)             console.log("\n***ChartsDMG is null")
        
        return <Dialog open={open} maxWidth="xl" PaperProps={{sx: { background: "transparent"}}} >
                <DialogContent>
                    <Stack alignContent={"center"} justifyContent={"space-around"}>
                        <CircularProgress size={100} color={"inherit"}/>
                    </Stack>
                </DialogContent>
                {/* <Button onClick={handleClose} color="error">Cancel</Button> */}
            </Dialog>
        // return <></>
    } 


    // NO DATA 
    if (chartsWD.every(chart => isChartEmpty(chart))) {
        return <Dialog open={open} maxWidth="xl" >
                <DialogTitle margin={2}>
                    <Box> DAMAGE FORECAST </Box>
                </DialogTitle>
                
                <DialogContent>
                    <Alert severity="error">
                        <Typography> No data available for this building (feature id #{feature.getId()}) </Typography>
                    </Alert>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="error">Close</Button>
                </DialogActions>
            </Dialog>
    }


    // DATA AVAILABLE
    return <Dialog open={open} maxWidth="xl"  >
      <DialogTitle margin={2}>
        <Box> DAMAGE FORECAST  </Box>
      </DialogTitle>
      
      <DialogContent>
        
        <Stack direction={"row"}>

            {/* ---------------------------- LEFT PANE ----------------------------  */}
            <Stack direction={"column"} spacing={1} >
                {/* HEADER */}
                 <Stack direction="row" justifyContent={"space-around"} spacing={2} paddingLeft={4} paddingRight={4} paddingTop={1} >
                    {
                        SCENARIOS_LABELS.map((scenario, i) => {
                            if (!isChartEmpty(chartsWD[i])) {
                                return <Box key={i}> <Typography>{scenario}</Typography> </Box>
                            }
                            return null
                        })
                    }    

                    {
                        atLeastTwoCharts() ? 
                            <Typography>
                                {aggregateFunction}
                            </Typography> 
                        : <></>
                    }
                    
                </Stack>


                {/* WATER DEPTH CHARTS */}
                <Stack direction="row" justifyContent={"space-between"} spacing={2} paddingLeft={4} paddingRight={4} >
                    <Stack direction={"row"} spacing={1}>
                        {
                            chartsWD.map((chart, i) => {
                                
                                /** Show the chart column ONLY IF the values are available for that building */
                                if (!isChartEmpty(chart)) {
                                    return  <Box key={i}>
                                                <DamageBarChart
                                                    color={"#00a"}
                                                    series={chart}
                                                    categories={RETURN_TIME_LABELS}
                                                    maxY={maxValue(chartsWD)}
                                                    yAxisTitle={ !i ? "water depth (m)":""}
                                                />
                                            </Box>
                                }
                                return null
                            })
                        }

                        {/* MAX WATER DEPTH CHART */}
                        {
                            // if there is at more than one non-empity chart, show the max water depth chart
                            atLeastTwoCharts() ?
                                <DamageBarChart
                                    color={"#00d"}
                                    maxY={maxValue(chartsWD)}
                                    series={chartMaxWD}
                                    categories={RETURN_TIME_LABELS}
                                    // yAxisTitle={"water depth (m)"}
                                />
                            : <></>
                        }
                        
                    </Stack>
                   
                </Stack>

                {/* DAMAGE CHARTS */}
                <Stack direction="row" justifyContent={"space-between"} spacing={2} paddingLeft={4} paddingRight={4} paddingTop={2} >
                    <Stack direction={"row"} spacing={1}>
                        {
                            chartsDMG.map((chart, i) => {
                                if (!isChartEmpty(chartsWD[i])) {
                                    return  <Box key={i}>
                                                <DamageBarChart
                                                    color={"#a00"}
                                                    series={chart}
                                                    categories={RETURN_TIME_LABELS}
                                                    maxY={buildingMaxValue}
                                                    yAxisTitle={!i ? "Damage ($)":""}
                                                />
                                            </Box>
                                }
                                return null
                            })
                        }
                        {
                            (chartsWD.filter(chart => !isChartEmpty(chart)).length > 1) ?
                                <DamageBarChart
                                    color={"#d00"}
                                    maxY={buildingMaxValue}
                                    series={chartMaxDMG}
                                    categories={RETURN_TIME_LABELS}
                                />
                            : <></>
                        }
                        
                    </Stack>
                </Stack>

                {/* EAD FOOTER */}
                <Typography paddingLeft={9} paddingRight={4}>
                    EAD (Expected Annual Damage):
                </Typography>
                
                
                
                {/* SCENARIOS EAD */}
                <Stack direction="row" justifyContent={"space-around"} paddingLeft={9} paddingRight={4} >
                    {
                        SCENARIOS_LABELS.map((_, j) => {
                            if (!isChartEmpty(chartsWD[j])) {
                                return  <Stack key={j} borderTop={"1xp solid #ddd"} spacing={1}>
                                            <>{SCENARIOS_LABELS[j]}</>
                                            {
                                                computeEAD(chartsWD[j], chartsDMG[j]).map((ead, i) => {
                                                    return  <Stack key={i} direction="row" justifyContent={"space-between"} spacing={2}>
                                                                <Typography variant="caption"color="#aaa"> 
                                                                    {(PROJECTIONS_LABELS[i] !== "Historical" ? "(2050)" : "") + " " + PROJECTIONS_LABELS[i]} : 
                                                                </Typography>
                                                                <Typography variant="caption" color="#555"> {isNaN(ead) ? "n.a." : hugevalue(ead)+" $"} </Typography> 
                                                            </Stack>
                                                })
                                            }
                                        </Stack>
                            }
                            return null
                        })
                        // : <>/</>
                    }   

                    {/* MAX EAD FOOTER */}
                    {
                        atLeastTwoCharts() ?
                            <Stack borderTop={"1xp solid #ddd"} spacing={1}>
                                <>{aggregateFunction}</>
                                {
                                    maxEads.map((ead, i) => {
                                        return  !isNaN(ead) ? 
                                                    <Stack key={i} direction="row" justifyContent={"space-between"} spacing={2}>
                                                        <Typography variant="caption"color="#aaa"> 
                                                            {(i !== PROJECTIONS_LABELS.indexOf("Historical") ? "(2050)" : "") + " " + PROJECTIONS_LABELS[i]} : 
                                                        </Typography>
                                                        <Typography variant="caption" color="#555"> {isNaN(ead) ? "n.a." : hugevalue(ead) + " $"} </Typography> 
                                                    </Stack>
                                                : <></>
                                    })
                                }
                            </Stack> 
                        : <></>
                    } 

                    {/* 2030 EAD FOOTER */}
                    {
                        
                        (maxEads.filter(ead => ead !== undefined).length > 1) ? 
                            <Stack borderTop={"1xp solid #ddd"} spacing={1}>
                                <>Maximum (year {interpolationYear})</>
                                {
                                    
                                    maxEads.map((ead, i) => {
                                        return  !isNaN(ead) ? 
                                                    <Stack key={i} direction="row" justifyContent={"space-between"} spacing={2}>
                                                        {
                                                            (i !== PROJECTIONS_LABELS.indexOf("Historical")) ?
                                                            <>
                                                                <Typography variant="caption" color="#aaa">
                                                                    {`(${interpolationYear}) ${PROJECTIONS_LABELS[i]}`}
                                                                </Typography>
                                                                <Typography variant="caption" color="#555"> 
                                                                    {
                                                                        isNaN(ead) ?  "n.a." 
                                                                        : 
                                                                        hugevalue(Math.round(linear_interpolation(ead, maxEads[PROJECTIONS_LABELS.indexOf("Historical")], 2050, 2020, 2030))) + " $"
                                                                    } 
                                                                </Typography> 
                                                            </>
                                                            : <></>
                                                        }
                                                        
                                                    </Stack>
                                                : <></>
                                    })
                                }
                            </Stack> 
                        : <></>
                    }   
                
                    
                </Stack>
                
            </Stack>



            {/* ---------------------------- RIGHT PANE ----------------------------  */}
            <Stack direction={"column"} marginTop={2} spacing={2} borderLeft={"1px solid #ddd"}>

                <Stack direction="row" alignItems={"center"} justifyContent="space-around">
                    
                    {/* SELECT COMBO BOX */}
                    <Box sx={{ display: 'flex', alignItems: 'left' }}>
                        <FormControl sx={{minWidth: 180, width: 180}}>
                            <InputLabel size="small">Preset function</InputLabel>
                            <Select 
                                label="Preset function"
                                size="small" 
                                value={selectedOption} 
                                onChange={handleSelectPreset} 
                                >
                                {   
                                    damageOptions ?
                                    damageOptions.map((option, i) => 
                                        <MenuItem key={i} value={option}> {option.name} </MenuItem>
                                    )
                                    :<>No features</>
                                }
                            </Select>
                        </FormControl>
                        
                       
                    </Box>

                    {/* TEXT FIELD */}
                    <FormControl sx={{minWidth: 200, width: 200}}>
                        <TextField 
                            value={presetName}
                            style={{width: 200}} 
                            label={"Rename function"} 
                            // helperText={`Rename ${type} function`}
                            size="small"
                            variant="outlined" 
                            onChange={handleChangePresetTextField}
                            placeholder= {selectedOption ? selectedOption.name : presetName}
                        />
                    </FormControl>

                    {/* SAVE PRESET BUTTON */}
                        <IconButton disabled={presetSaved || !presetName} onClick={handleSaveFDamage} color="primary">
                            <Tooltip title={presetSaved ? "Preset saved" : "Save preset function"}>
                                {presetSaved ? <BookmarkAddedIcon/> : <BookmarkAddIcon />}  
                            </Tooltip>     
                            <Typography>{presetSaved ? "Saved" : "Save"}</Typography>     
                        </IconButton> 

                </Stack>
                        
                {/* GRAPH */}
                <DamageFunctionChart 
                    fixedSeries={oldSeries} 
                    customSeries={plotPoints} 
                    onEditSeries={handleEditSeries}
                    seriesName={selectedOption ? selectedOption.name : presetName}
                /> 
                
            
                {/* EDITOR */}
                <Box>
                    <Stack direction="row" spacing={5} alignContent={"center"} justifyContent={"space-between"} paddingLeft={4} paddingRight={4} paddingTop={4} >
                        <NumberField 
                            value = {valueM2}
                            min = {featureOptions.minValue}
                            max = {featureOptions.maxValue}
                            onChange = {handleChangeSliderValue}
                            variant = "standard" 
                            um = {"$"} 
                            sx={{width: 200}}   
                            description = {`Value exposed asset per m2`}                      
                        />
                        <Slider 
                            value = {valueM2}
                            min = {featureOptions.minValue}
                            max = {featureOptions.maxValue}
                            step = {featureOptions.step}
                            onChange = {handleChangeValueM2}
                            variant="standard"
                            sx={{width: 200}}   
                        />
                    
                    </Stack> 
                        
                        
                    {/* BUILDING INFO */}
                    <Stack spacing={1} padding={2}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography> Area of the building (m2)</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {/* <Typography> {hugevalue(area)} m2 </Typography> */}
                                <TextField value={area} variant="standard" size="small" onChange={handleAreaFieldChange}/>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Typography> Total value </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography> {hugevalue(buildingMaxValue)} $ </Typography>
                            </Grid>
                        </Grid>

                    </Stack>
                </Box>
            </Stack>
        </Stack>


      </ DialogContent>

      {/* ACTIONS */}
    <DialogActions >
        <Stack direction="row" alignItems="center" spacing={3} justifyContent={"space-between"} paddingBottom={0}>
            <Button variant="outlined" onClick={handleExport} startIcon={<FileDownloadIcon />}>EXPORT</Button>
            <Button variant="outlined" onClick={handleClose} color="error">Close</Button>
            
        </Stack>
    </DialogActions>
      </Dialog>
}


