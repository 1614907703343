import { addLayerToProject} from "@SaferPlaces2023/safer-map";
import { api_features } from "./utils/http";

/**
 * CreateShapeFileIfNotExists
 * @param {*} project 
 * @param {*} layername 
 * @param {*} schema 
 * @returns 
 */
export function CreateShapeFileIfNotExists(project, layername, groupname){

  // schema = {
  //   'geometry': 'Point', 
  //   'properties': { 'name': 'str' } }
  // }

  let layer = project.map.getLayerByName(layername)
  let authid = project.map.getProjectionCode()
  if (!layer){

      let FeatureCollection = {
          type:"FeatureCollection",
          features:[],
          crs:{ type:"name", properties:{name: authid} }
      }
 
      return api_features({ features:FeatureCollection, layername:layername}).then(response=>{
        //console.log("api_features: ", response)
        if (response.status === "success") {
            return addLayerToProject({
              project:project, 
              filename:response.data.filename, 
              groupname:groupname, 
              visible:true, 
              permanent:true
            }) 
          }
          return response
      })
      
  }//end if not layer
  return Promise.resolve({status: "success", features:[], layername:layername})
}//end function