
export default class Paths {
    static ROOT             = ""
    static LOGIN            = "/login"
    static SIGNIN           = "/signin"
    static FORGOT_PASSWORD  = "/forgot-password"
    static NEW_PROJECT      = "/new-project"
    static MY_PROJECTS    = "/my-projects"
    static PROJECT_PAGE     = "/project"
    static TEST_PAGE        = "/test"
}

