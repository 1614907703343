import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEvent } from '../../utils/events';
import {Tabs, Tab} from "@mui/material"
import Box from "@mui/material/Box"
import { SoftwareUpdatePanel } from './SoftwareUpdatePanel';
import ProjectSettingsPanel from './ProjectSettingsPanel';
import UserSettingsPanel from './UserSettingsPanel';
import ModelSettingsPanel from './ModelSettingsPanel';
import TabPanel from '../../widgets/TabPanel';
import { PatchPanel } from './PatchPanel';
import { isDeveloper } from '../../utils/http';

export default function SettingsDialog() {
    
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('paper')

  const handleOpen = () => {
    setOpen(true);
    setScroll("paper");

  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEvent("dialog:settings", handleOpen)
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title"> Settings </DialogTitle>
      
        <DialogContent dividers={true}>   


          <Box sx={{ flexGrow: 1, display: 'flex', height: 550, width: 900 }} >          
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={handleChangeTab}
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                  <Tab label="User Settings"  />
                  <Tab label="Project Settings" />
                  <Tab label="Model Settings" />
                  <Tab label="Software version" />
                  <Tab disabled={!isDeveloper()} label="Software Patches" />
            </Tabs>
              
              <TabPanel value={selectedTab} index={0}>
                <UserSettingsPanel />
              </TabPanel>
              
              <TabPanel value={selectedTab} index={1}>
                <ProjectSettingsPanel />
              </TabPanel>

              <TabPanel value={selectedTab} index={2}>
                <ModelSettingsPanel />
              </TabPanel>

              <TabPanel value={selectedTab} index={3}>
                <SoftwareUpdatePanel />
              </TabPanel>
              
              <TabPanel value={selectedTab} index={4}>
                <PatchPanel />
              </TabPanel>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>

      </Dialog>
    </>
  );
}