import { Divider, List, Typography } from "@mui/material";
import { ButtonConfirm } from "../../widgets/mui/ButtonConfirm";
import { Spacer } from "../../widgets/mui/Spacer";
import { isDeveloper, patch } from "../../utils/http";
import { getProjectName } from "../../utils/const";
import { HealthAndSafety } from "@mui/icons-material";

export const PatchPanel = ()=>{



    return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <Typography variant="H7">This panel is available only to software developers</Typography>    
                <Divider />
                <Spacer />


                <ButtonConfirm hidden={!isDeveloper()} confirm={{description:"If you confirm the project will be patched!"}} variant="contained" onClick={()=>{
                    const projectname = getProjectName()
                    patch(projectname)
                }}><HealthAndSafety/>Pacth this Project</ButtonConfirm>
                <Spacer />

                <ButtonConfirm hidden={!isDeveloper()} confirm={{description:"If you confirm all yours project will be patched!"}} variant="contained" onClick={()=>{
                    patch("*")
                }}><HealthAndSafety/>Pacth all user's Projects</ButtonConfirm>
        </List>
}

