import { Slider } from "@mui/material"


export const DeltSlider = ({ value, onChange }) => {

    const marks = [
        {label:"6\"", value:6},
        //{label:"10\"", value:10},
        {label:"1'", value:60},
        {label:"2'", value:120},
        {label:"5'", value:300},
        {label:"10'", value:600},
        {label:"15'", value:900}
    ]

    const valueLabelFormat = (value) => `${value}s`

    return (

        <Slider 
            value={value}
            onChange={onChange}
            valueLabelDisplay="on"
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            step={null} //to force marks steps
            marks={marks}
            min={6}
            max={900}
        />

    )

}//end ManningSlider