import { useContext, useEffect, useState} from "react";
import { addjob, removejob } from "../utils/addjob";
import strftime from "strftime";
import {Button, FormHelperText, Stack} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import DigitalTwinContext from "../WizardComponents/DigitalTwinContext";
import { len, listify } from "../utils/strings";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import Tooltip from '@mui/material/Tooltip';
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import ClearIcon from '@mui/icons-material/Clear';
import { useConfirmDialog } from "react-mui-confirm";

/**
 * @param {*} dataset 
 * @param {*} t_srs 
 * @param {*} band 
 * @param {*} cmap 
 * @param {*} progress 
 * @param {*} bbox 
 * @param {*} tooWideArea 
 * @param {*} textWhenDisabled 
 */
export default function EedemButton({
    dataset, 
    band, 
    cmap, 
    progress,
    bbox, 
    tooWideArea = false,
    textWhenDisabled = "",
    ...props
}) {
    
    const [project,] = useContext(QgisContext);
    const [params, ] = useContext(DigitalTwinContext);
    const [downloading, setDownloading] = useState(false);
    const Q = new QgisProject(project);
    const confirm = useConfirmDialog()

    const handleClick = (e) => {

        if (!tooWideArea && !downloading){
            handleDownload(e)
        }else if (downloading){
            stopDownload()
        }

    }

    const handleDownload = (e) => {

        setDownloading(true)
        
        const datasets = listify(dataset)       //listify(params.dataset)
        const bands    = listify(band) 
        const project_dir = Q.getProjectName()

        let t_srs = params.t_srs
        t_srs = t_srs.proj4 || t_srs
        const job_ids = []
        

        for (let j = 0; j < datasets.length; j++) {

            let dataset = datasets[j]
            let dataset_name = datasets[j].replaceAll("/","_")
            const ext = (["OSM/BUILDINGS", "ESA/WorldCover/v100", "ESA/WorldCover/v200", "LANDUSE/RER"].includes(dataset)) ? "shp" : "tif"
            const HMS = strftime("%H%M%S")
            const jid = (new Date().getTime())+parseInt(Math.random()*1000000)

            if (dataset_name === "SAFER002_DEM"){
                // mokup for SAFER002
                dataset = "NASA/NASADEM_HGT/001"
            }
            if (dataset_name === "ESA_WorldCover_v100"){
                dataset_name = "inf_rate"
            } 
            
            const options = {
                jid:         jid,
                name:        `EEDEM${HMS}`,
                type:        "eedem",
                project:     project_dir,
                mode:        "local",
                out:         `${project_dir}/${dataset_name}_${HMS}.${ext}`,
                dataset:     dataset,
                band:        bands[j] || "elevation", 
                t_srs:       t_srs, 
                bbox:        bbox || params.bbox,    // context .bbox
                cmap:        cmap ? listify(cmap)[j] : "viridis"       // 
            }
            
            if (dataset_name === "SAFER002_DEM"){
                // mokup for SAFER002
                options.out= `${project_dir}/SAFER002_DEM_TEMP.tif`    
            }
            
            addjob(options)
            job_ids.push(options.jid)

        }
        
        props.onClick(job_ids)

        
    }//end handleDownload


    const isLoading = (progress) => progress !== null && progress > 0 && progress < 100

    const stopDownload = () => {
               
        confirm({
             title: "Stop Download",
             description: "Are you sure to stop the download?",
             onConfirm:  () =>{ removejob(Q).then(()=>{setDownloading(false)}) }//removejob,
        });

    }
    
    useEffect(() => {

        const isDownloading = isLoading(progress)
        if (downloading!==isDownloading){
            setDownloading(isDownloading)
        }
        
    }, [downloading, progress])


    return <>
        <Stack direction="row" spacing={2}></Stack>
            <Stack direction="column" spacing={2}>
                <Tooltip title={tooWideArea ? textWhenDisabled : ""} followCursor={true}>
                    <>
                    <Button
                        disabled = {len(bbox)===0 || tooWideArea}
                        variant = {downloading ?"outlined":"contained"}
                        onClick = {handleClick}
                        sx={{ mt: 0, mr: 1 }}
                        startIcon={ downloading ? <ClearIcon size={24} onClick={stopDownload}/>: <DownloadIcon />}
                        endIcon = { downloading ? <CircularProgressWithLabel value={progress} size={24}/>: null}
                        color = "primary"
                    >
                        {props.children}
                    </Button>
                                    
                    </>
                </Tooltip>
            
            <FormHelperText sx={{color:"red"}}>{textWhenDisabled}</FormHelperText>
        </Stack>
    </>

}