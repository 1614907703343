import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const LinearProgressWithLabel = (props) => {

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        
        <Box sx={{ minWidth: 80 }}>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary'}}
          >{`${props.label}`}</Typography>
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value = {props.value||0} {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary'}}
          >{`${Math.round(props.value||0)}%`}</Typography>
        </Box>
      </Box>
    );
}
  