import React, { useEffect, useRef, useState } from 'react'
import { TextField } from '@mui/material'
import { upload } from '../utils/http'
import { PolygonLayerIcon } from '../icons/MapIcons'
import { justext } from '../utils/filesystem'
import { listify, lower } from '../utils/strings'
import shp from "shpjs"
import Autocomplete from '@mui/material/Autocomplete'
import Stack from "@mui/material/Stack"
import JSZip from "jszip"

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export function UploadGeodata({
    props,
    cmap,

    onChangeField = () => {},
    onUpload = () => {},

    mandatory_fields = [], 
    datatype = "geodata",       // accepted: vector, raster
    geometry_type = ["point", "multipoint", "linestring", "polygon", "multipolygon"],
    fieldSelector = false,      // whether to show or not the field selector (in case of vector only)
    maxSize = 2e9,           //if not provived from props, 1 Gb is the default max dimension for an uploaded file
    t_srs = "",                   // destination srs
    disabled = false,
    shapefile_selector_label = "Field"
}){
    
    const ref = useRef();

    // textfield
    const [text, setText]   = useState("")                      // text in the textfield
    const [percent, setPercent] = useState(0)
    const [uploadStatus, setUploadStatus] = useState("")        
    const [uploadError, setUploadError] = useState(false)

    // combobox
    const [selectedOption, setSelectedOption] = useState(null)  // value of the combobox
    const [options, setOptions] = useState([])                  // options of the combobox
    const [fieldStatus, setFieldStatus] = useState("")
    const [fieldError, setFieldError] = useState(false)
    const [hideComboBox, setHideComboBox] = useState(true)
    
    const allowedGeometries = listify(geometry_type)            // list of geometries allowed (can be single element if the prop is a string)  

    const mandatory_types = {                                   // needed file types in case of vector files uploading
        "vector": ["shp", "dbf", "prj", "shx"],
        "raster": ["tif"],
    }


    /** File visualizzati dal finder */
    const filter_types = {                                  
        "vector": [".shp", ".zip", ".dbf", ".prj", ".shx", ".txt"],
        "raster": [".tif", ".zip"],
        "geodata": [".tif", ".shp", ".zip", ".dbf", ".prj", ".shx", ".txt"]
    }[datatype]


    // reset the fields status if an error in uploading occurrs
    useEffect(() => {
        if (uploadError) {
            setText("")
            setFieldStatus("")
            setOptions([])
            setSelectedOption(null)
        } 
    }, [uploadError])


    const handleChangeOption = (event, option) => {
        //console.log("[handleChangeOption] option: ", option)
        if (option) {
            setSelectedOption(option)
            onChangeField(option)
        }
    }

    const handleOnClose = (event, reason) => {
        if (reason === "selectOption" && selectedOption) {
            onChangeField(selectedOption)
        }
    }

    /**
     * Extract the column names of the .dbf file. Set the attribute keys which is null by default, as an array of unique keys
     * @param fileDbf File (implementing the arrayBuffer method) 
     * @return Array of strings. If the file is missing, returns null. */
    function getDBFFileColumns(fileDbf) {
        if (fileDbf){
            return fileDbf.arrayBuffer()
            .then((buffer) => {
                let array = shp.parseDbf(buffer) 
                if (array.length) {
                    let record = {...array[0]}
                    array = null //de-allocate
                    let fieldnames = record ? Object.keys(record) : []
                    fieldnames = fieldnames.filter( name => !name.startsWith("__"))
                    return fieldnames
                }
                return null
            })
        } else {
            return null
        }
    }

    /** Returns the type of the geometry of the file
     * @param fileShp File (implementing the arrayBuffer method) */
    function getGeometry(fileShp) {
        return fileShp.arrayBuffer()
        .then((buffer) => {
            let array = shp.parseShp(buffer)
            if (array.length) {
                let geometry = array[0].type
                array = null // de-allocate
                return lower(geometry)
            } else {
                return null
            }
        })
    }



    /**
     * Return all the files with a same extension, from a list of files
     * @param files List of file objects. Each one must have the "name" attribute
     * @param type (string) Extension of the file
     * @returns List of files
     */
    function getFilesOfType(files, type){
        let same_type_files = Array.from(files).filter((file) => (
            lower(justext(file.name)) === type))
            
        return same_type_files ? same_type_files : null
    }
   


    /**
     * Extract a file (first occurrence) from a zip file, having a specific extension
     * @param {*} fileZip zip file
     * @param {*} fileExtension string. Provided without the "dot". Ex: to extract foo.txt use just "txt"
     */
    function extractFileFromZip(fileZip, extension) {
        return new JSZip().loadAsync(fileZip).then((zip) => {
            //console.log("\n[extractFileFromZip]\nzip files", zip.files)
            const fileKey = Object.keys(zip.files).filter(      // viene estratta la chiave del file con l'estensione scelta
                name => (lower(justext(name)) === extension)
            )[0]
            
            //console.log("\n[extractFileFromZip]\n file key", fileKey)

            return zip.files[fileKey].async("blob")             // l'oggetto viene convertito in blob (File implementa l'interfaccia Blob ma File non è disponibile)
                .then((obj) => {
                    obj.name = fileKey                          // viene agigunta una key uguale al nome (come in File)
                    return obj
                })
        })
    }


    /**
     * Returns all the names of the files contained in a zip file
     * @param {*} fileZip 
     * @returns 
     */
    function getFileNamesFromZip(fileZip) {
        return new JSZip().loadAsync(fileZip).then((zip) => {
            return Object.keys(zip.files)
        })
    }


    /**
     * Returns all the names of the files contained
     * @param {*} fileZip 
     * @returns 
     */
    function getFileNamesFromList(fileList) {
        return Array.from(fileList).map((item) => item.name)
    }
        

     /**
     * Check if the required vector file types are respected by the uploaded files.
     * @param files List of file names
     * @returns The list of the missing mandatory types. An empty list is considered as the correct situation
     */
    function getMissingExtensions(fileNames, required_extensions) {
        //console.log("[get missing extension]\nfiles: ", fileNames, "\nrequired: ", required_extensions)
        let extensions = Array.from(fileNames).map(
            (name) => lower(justext(name)))    
        
        let missing_types = required_extensions.filter(
            (ext) => !extensions.includes(ext))
        
        return missing_types
    }


    /** Return true if the sum of the sizes of the files in the 
     * list exceeds the maximum size allowed */
    function sumOfSizesExceed(fileList) {
        let sum = 0
        for (let file of fileList) {
            sum += file.size
        }
        return (sum > maxSize)
    }


    /**
     * Check if the uploaded .dbf file contains all the mandatory fields. 
     * @param {*} filedbf 
     * @returns The list of the missing mandatory fields. An empty list is considered as correct situation. Returns a null value in case of all fields missing or empty dbf file
     */
     async function validateFields(filedbf) {
        let fields = await getDBFFileColumns(filedbf)
        if (fields) {    
            setOptions(fields)                 
            setSelectedOption(fields[0])   
            return mandatory_fields.filter(         // return the list of fields which are missing
                (field => !fields.includes(field)))
        } else {
            return null                             
        }
    }


    // TODO | questa funzione va rivista. Gerarchizzare meglio i controlli: zip/noZip - inferire raster/vector
    /** 
     * Manage the upload of the files
     */
    async function validation(files) {

        let fileNames = []
        let zipUploaded = (getFilesOfType(files, "zip").length > 0)
        let rasterUploaded = (getFilesOfType(files, "tif").length > 0)
        let nTifFiles = 0
       

        /* **********************
               RASTER UPLOAD    
        ********************** */
        // if a raster file has been uploaded, ensure it's only 1 file
        if (rasterUploaded) {
            setHideComboBox(true)
            if (files.length > 1) {
                setUploadStatus("If using raster files, only a single file can be uploaded.")
                setUploadError(true)
                return false     
            }
            else {
                return true // no need for further controls
            }
        }

        /* **********************
                ZIP UPLOAD    
        ********************** */
        // if a zip file has been uploaded, ensure it's only 1 file
        if (zipUploaded) {   
            setHideComboBox(true)                                                           // if at a zip file is uploaded
            //console.log("files: ", files)
            if (files.length > 1) {                                                     //  if exactly 1 zip file is uploaded
                setUploadStatus("If using zip archives, only a single file can be uploaded.")
                setUploadError(true)
                return false               
            }   
            fileNames = await getFileNamesFromZip(files[0])                             // take the list of file names from the zip  
            
            // check if it is a empty zip archive
            if (fileNames.length === 0) {
                setUploadStatus("Please provide a non-empty zip archive")
                setUploadError(true)
                return false    
            } 

            
            // check if the zip contains raster files. If yes, it must be only 1
            nTifFiles = fileNames.filter((name) => lower(justext(name)) === "tif").length
            if (nTifFiles >= 1) {                                                             // if the zip contain at least one raster

                // check if all the required types for raster are satisfied
                let missing_types = getMissingExtensions(fileNames, mandatory_types.raster)
                if (missing_types.length) {
                    setUploadStatus("You are trying to upload a raster file but files of type " + missing_types.map((name) => `'.${name}'`).join(", ") + " are missing. ")
                    setUploadError(true)
                    return false
                }
                if (nTifFiles > 1) {                                                         
                    setUploadStatus("If using zip archives, only a single file can be uploaded.")
                    setUploadError(true)
                    return false     
                } else {
                    return true                                                                 // now we have a zip with a single raster file inside, no need for further validation
                }
            } else {
                fileNames = await getFileNamesFromZip(files[0])                                 // take the list of files contained in the zip
            }
        } else { 
            fileNames = getFileNamesFromList(files)                                             // take the list of file names from the file list
        }
        

        /* **********************
              VECTOR UPLOAD    
        ********************** */
        
        // at this point we know a group of vector file have been uploaded 
        setHideComboBox((rasterUploaded || nTifFiles >= 1) || !fieldSelector)

        if (!rasterUploaded) {

            // validate the extensions
            let missing_types = getMissingExtensions(fileNames, mandatory_types.vector)
            if (missing_types.length) {                                                     // if there are missing types
                setUploadStatus("You are trying to upload a raster file but files of type " + missing_types.map((name) => `'.${name}'`).join(", ") + " are missing. ")
                setUploadError(true)
                return false
            }

            // validate geometry
            let shpfile = zipUploaded ? await extractFileFromZip(files[0], "shp") : getFilesOfType(files, "shp")[0]    
            let geometry = await getGeometry(shpfile)
            if (geometry) {
                if (!allowedGeometries.includes(geometry)) {
                    setUploadStatus(`The provided vector file has geometry of type '${geometry}'. 
                                    Instead, a geometry of type ${allowedGeometries.join("/")} 
                                    is required.`)
                    setUploadError(true)
                    return false
                }
            } else {
                setUploadStatus("The provided file .shp is empty")
                setUploadError(true)
                return false
            }

            // check the presence of dbf file
            const dbffile = zipUploaded ? await extractFileFromZip(files[0], "dbf") : getFilesOfType(files, "dbf")[0]        
            if (!dbffile) {                                                                 // if dbf file is missing
                setFieldStatus("Impossibile to read the fields: File '.dbf' is missing") 
                setFieldError(true)
                return false
            }
        
            // check for missing fields in dbf file                                                                                                                                      
            let missing_fields = await validateFields(dbffile) 
            if (missing_fields === null) {
                setFieldStatus("File .dbf is missing or empty")
                setFieldError(true)
                return false
            }
            if (missing_fields.length) {                                                    // if there all the fields are present      
                setFieldStatus("Fields named " + missing_fields.map((name) => `'${name}'`).join(", ") + " in file .dbf are missing. ")
                setFieldError(true)
                return false
            }
        }
       
        return true
    }


    async function handleUpload(e) {

        if (e.target.value === "")                                                   // if no file is selected, do nothing
            return
        
        let files = e.target.files
        let valid = await validation(files)
    
        if (valid) {
            if (!sumOfSizesExceed(files)) {
                for (let file of files) {

                    setText(file.name)
                    setUploadStatus("")
    
                    upload(file, cmap, t_srs, (e) => { 
                        let percent = Math.round(e.loaded/e.total*100)
                        setPercent(percent)
                        if (percent <100){
                            setUploadStatus(`uploading...${ Math.round( e.loaded / (1024*1024)) } MB`)
                        }else{
                            setUploadStatus("processing file...wait please!")
                        }
                        
                    })
                    .then(response => {
                        if(response.data.status==="success") {
                            setUploadStatus(`${files.length} files uploaded.`)
                            setUploadError(false)
                            setFieldError(false)
                            setFieldStatus(false)
                            onUpload({
                                filename:   response.data.data.pathname, 
                                t_srs:      response.data.data.spatialrefsys,
                                field:      selectedOption
                            })
                        } else {
                            setUploadStatus(response.data.data)
                            setUploadError(true)
                        }
                    })
                }
            } else {
                setUploadStatus("The sum of the file sizes cannot exceed " + Math.round(maxSize / 10**9) + "Gb")
                setUploadError(true)
            }            
        } 
    }

    
    return (
        <Stack spacing={2} >

            <TextField variant="filled"
                label = {`${percent}%`}
                error={uploadError}
                helperText={uploadStatus}
                value={text}
                disabled={disabled}
                InputProps={{
                    readOnly:true,
                    startAdornment:(<PolygonLayerIcon/>),
                }}{...props} 
                onClick={()=>{ref.current.click()}}
            />

            <Autocomplete
                onChange={ handleChangeOption}
                onClose={handleOnClose}
                disablePortal // ! <------------------------ cos'è???
                value={selectedOption}
                hidden={hideComboBox}
                options={options}
                disabled={!options.length}  
                defaultValue={options.length ? options[0] : null}
                renderInput={(props) => <TextField 
                                            // style={{ margin: 0 }}
                                            {...props} 
                                            label={shapefile_selector_label}
                                            helperText={fieldStatus} 
                                            error={fieldError}
                                        />}
            />
    
                     
            
            <input
                ref={ref}
                hidden
                //accept="application/octet-stream"
                accept = {filter_types}
                multiple 
                type="file" 
                onChange={handleUpload}
            />
        
        </Stack>
    )
}