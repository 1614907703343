
import React, { useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import { Paper, Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { http } from '../utils/http';
import { LoadingButton } from '@mui/lab';
import background from "../images/rect5.png"
import { loginBoxStyle } from '../themes';
import saferplaces_logo from "../images/Safer Places - Logo.png";
import strftime from 'strftime';
var md5 = require('md5');

export default function ForgotPasswordPage() {  

    const navigate = useNavigate()
    const buttonstyle = {margin: "20px auto"}
    const [username, setUsername] = useState(sessionStorage.getItem("email")||"")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    

    useEffect(() => {
        if (!username) {
            setUsername("")
        }
        sessionStorage.removeItem("email")
    }, [username])

    const handleBack = () => {
        navigate("/login")
    }

    function handleChangeUsername(event) {
        setUsername(event.target.value)
    }

    function showSuccess() {
        setError(false)
        setSuccess(true)
        setMessage("Done! Please check your email inbox.")
    }

    function showError(msg) {
        setMessage(msg)
        setError(true)
        setSuccess(false)
    }

    function handleConfirm() {
        // provide a better validation of the input! 

        if (username.trim() === "" || !username.includes("@")) {
            showError("Please provide a valid email address")
        } else {
            setLoading(true)
            setTimeout(() => handleReset(), 2000);
        }
    }
    


    const handleReset = () => {

        setLoading(true)
        let challenge = md5(username+strftime("%Y%m%d"))
        http.get(`/api/reset?username=${username}&challenge=${challenge}`)
            .then(() => {
                setUsername("")
                showSuccess()
                setLoading(false)
            })
            .catch(err => {
                showError("Unkown error")
                setLoading(false)
            })
        }


    
    return (

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx = {{backgroundImage:`url(${background})` }}
        >
            <Paper elevation={10} style={loginBoxStyle}>
                
                <Grid container direction="column" aling="center">

                    <Grid item >
                        <img src={saferplaces_logo} alt="saferplaces logo" height="40" style={{ padding: 5 }} />
                    </Grid>

                    <Grid item>
                    <h2>Retrieve password</h2>
                    </Grid>

                    <Grid item>
                    Insert the email address you used for your Saferplaces account. 
                    A new password will be sent to you in some minutes.
                    </Grid>
                

                    <Grid item>
                        <form noValidate autoComplete="off">
                            <TextField
                                value={username}
                                onChange={handleChangeUsername}
                                variant="outlined"
                                placeholder="Insert your email"
                                margin="normal"
                                type="text"
                                fullWidth
                                helperText={message}
                                color={success ? "success": ""}
                                focused
                                error={error}
                                />
                        </form>
                    </Grid>
                    
                
                    <Grid item>
                        <LoadingButton type="submit" fullWidth style={buttonstyle} onClick={handleConfirm} 
                                    variant="contained" loading={loading}>
                        Send me a new password
                        </LoadingButton>
                    </Grid>
                    

                    <Button onClick={handleBack} >
                        Back to login
                    </Button> 
                </Grid>
            </Paper> 
        </Box>
    );
    
}