import React from "react";
import { useNavigate } from 'react-router-dom';
import { CardMedia, Card, CardHeader, LinearProgress } from "@mui/material";
import ContextMenu from "./ContextMenu"
import Paths from "../utils/paths";
import { preventDefault } from "../utils/events";
import { getUserName } from "../utils/const";
import { Warning as WarningIcon}  from '@mui/icons-material';



export default function ProjectCard({ projectname, date, disabled, warning }) {
  
  const username = getUserName()
  //const imageUrl = `/api/resource/${projectname}/screenshot.png`
  const imageUrl = `/projects/${username}/${projectname}/screenshot.png`

  const navigate = useNavigate()

  const progressBarVariant = (disabled) ? "indeterminate" : "determinate"

  const openProject = () => {
    if (!disabled)
      navigate(Paths.PROJECT_PAGE + "?projectname=" + projectname)
  }

  return (
    <Card 
      onContextMenu={preventDefault}  
      elevation={6} 
      sx = {{minHeight:"20%", minWidth:"40%", color:(disabled)?"gray":"black", opacity:(disabled)?0.5:1}}
      
    >
      <CardHeader
        action={ <ContextMenu projectname={projectname} /> }
        title={
          <div onClick={openProject} style={{cursor:"pointer"}}>
            {projectname}
            {warning && <WarningIcon color="warning"/>}
          </div>
        }
        subheader={date}
      />
      <LinearProgress variant={progressBarVariant} value={100}/>
      <CardMedia 
          onClick={openProject}
          component="img"
          height="200"
          image={imageUrl}
      />       

    </Card>
  );
}
